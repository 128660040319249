import React, { useEffect, useState } from 'react'
import { Icon } from '@material-ui/core'

import './counter.scss'


const Counter = ({ title, desc, cost, max = 10, change }) => {
  const [now, setNow] = useState(0)

  const changeVal = (val) => {
    let cnt = now + val

    setNow(cnt < 0 ? 0 : (cnt > max ? max : cnt))
  }

  useEffect(() => {
    change(now)
  }, [now])


  return (
    <div className="counter">
      <div className="content">
        <div className="info top">
          <h2>{title}</h2>
          <p className="desc">{desc}</p>
        </div>

        <div className="btm">
          <div className="people">
            <span>1명</span>
            <div className="cost">{(cost*1).toLocaleString()}<div className="won">원</div></div>
          </div>

          <div className="count">
            <div className="btn" onClick={() => changeVal(-1)}><Icon>remove</Icon></div>
            <div className="cnt">{now}</div>
            <div className="btn" onClick={() => changeVal(1)}><Icon>add</Icon></div>
          </div>
        </div>
      </div>

      <div className="total">
        <div>총 금액</div>
        <div className="cost"><span>{(cost * now).toLocaleString()}</span>원</div>
      </div>
    </div>
  )
}

export default Counter