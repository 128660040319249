import React from 'react'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import './mobileMenu.scss'
import home from "~assets/images/icon/home.png"
import tour_search from "~assets/images/icon/tour_search.png"
import instructor from "~assets/images/icon/instructor.png"
import scuba from "~assets/images/icon/scuba.png"
import my from "~assets/images/icon/my.png"
import point_search from "~assets/images/icon/point_search.png"

import { signDialogState } from '~/stores/ui/sign'


const MobileMenu = () => {
  const setOpen = useSetRecoilState(signDialogState)

  return (
    <div className="mobileBottomMenu">
      <Link to="/">
        <div className="iconBox"><img src={home} alt="icon" /></div>
        <p>홈</p>
      </Link>
      <Link to="/tours">
        <div className="iconBox"><img src={tour_search} alt="icon" /></div>
        <p>투어</p>
      </Link>
      <Link to="/instr">
        <div className="iconBox"><img src={instructor} alt="icon" /></div>
        <p>강사</p>
      </Link>
      <Link to="/scuba">
        <div className="iconBox"><img src={scuba} alt="icon" /></div>
        <p>스쿠버샵</p>
      </Link>
      <Link to="/point/search">
        <div className="iconBox"><img src={point_search} alt="icon" /></div>
        <p>포인트</p>
      </Link>
      <a onClick={() => setOpen(true)}>
        <div className="iconBox"><img src={my} alt="icon" /></div>
        <p>로그인</p>
      </a>
    </div>
  )
}

export default MobileMenu