import React from 'react'
import clsx from 'clsx'

import './image.scss'

export default function Image({ className, src, width = 'auto', height = 'auto', size = 'cover', pos = 'center' }) {
  return (
    <div
      className={clsx('UI-image', className)}
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: size,
        backgroundPosition: pos,
        width,
        height
      }}
    />
  )
}
