import React, { useState } from 'react'
import { CircularProgress, Fab, TextField, MenuItem, Button, Icon } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/ko'

import ArrowRightIcon from '~/assets/images/icon/sign-arrow-right.png'
import ArrowLeftIcon from '~/assets/images/icon/sign-arrow-left.png'
import DoneIcon from '~/assets/images/icon/sign-done.png'

import Checkbox from '~ui/checkbox/Checkbox'
import { Text } from './text'

moment.locale('ko')



export default function SignUp({ setType, setOpen }) {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [role, setRole] = useState('user')
  const [more, setMore] = useState(false)
  const signTerms = [
    {
      id: 'terms_accept',
      label: '이용약관 동의',
      required: true,
    },
    {
      id: 'privacy_accept',
      label: '개인정보수집 및 이용 동의',
      required: true,
      onClick: () => {
        setMore(true)
      }
    },
    {
      id: 'pay',
      label: '전자금융거래 이용약관',
      required: true,
    },
    {
      id: 'location_accept',
      label: '위치기반서비스 이용약관 동의',
      required: true,
    },
    {
      id: 'card',
      label: '이메일 및 SMS 광고성 정보 수신동의',
      required: false,
      info: '다양한 프로모션 소식 및 정보를 보내드립니다.'
    },
  ]

  const [checked, setChecked] = useState(signTerms.reduce((acc, next) => ({
    ...acc,
    [next.id]: false
  }), {}))

  const [formValues, setFormValues] = useState({
    id: '',
    pw: '',
    rpw: '',
    nickname: '',
    gender: '',
    birthday: null,
    phone: '',
    code: '',
    validPhone: false,
    email: '',

    // 사용자
    license: '',
    divingCount: '',
    divingFavorPlace: '',

    // 프리랜서
    association: '',
    divingLicenseNumber: '',

    // 다이브샵 & 리조트
    companyName: '',
    businessType: '',
    businessNumber: '',
    address: '',
    website: ''
  })


  // 1단계 유효성 검사
  const validateTerms = async () => {
    let valid = true

    for (let signTerm of signTerms)
      if (signTerm.required && !checked[signTerm.id])
        valid = false

    if (!valid)
      return '필수항목을 동의해주세요.'
  }

  // 2단계 유효성 검사
  const validateInfo = async () => {
    const userId = formValues.id.trim()

    if (userId.length < 1) {
      return '아이디를 입력해주세요.'
    }
    
    if (userId.length > 10) {
      return '아이디는 10자 이내로 입력해주세요.'
    }

    if (!(/^[a-z0-9_-]{5,20}$/.test(userId))) {
      return '아이디는 5~20자의 영문 소문자, 숫자와 특수기호(_),(-)만 사용 가능합니다.'
    }
    
    if (formValues.pw.trim().length < 1) return '패스워드를 입력해주세요.'
    if (formValues.rpw.trim().length < 1) return '패스워드 확인을 입력해주세요.'
    if (formValues.pw !== formValues.rpw) return '패스워드가 일치하지 않습니다.'
  }

  // 3단계 유효성 검사
  const validateStep3 = async () => {
    if (role !== 'shop') {
      const nick = formValues.nickname.trim()
      if (nick.length < 1) return '닉네임을 입력해주세요.'
      if (nick.length < 2) return '닉네임은 최소 2자이상 입력해주세요.'
      if (!formValues.birthday) return '생일을 선택해주세요.'
      if (!formValues.phone.trim().length) return '연락처를 입력해주세요.'
    }

    if (!formValues.email.trim().length) return '이메일을 입력해주세요.'

    if (role === 'pre') {
      if (!formValues.divingCount.trim().length) return '다이브 로그 수를 입력해주세요.'
      if (!formValues.divingLicenseNumber.trim().length) return '다이브 강사 자격증 번호를 입력해주세요.'
      if (!formValues.association.trim().length) return '소속 협회를 입력해주세요.'
    }

    if (role === 'shop') {
      if (!formValues.companyName.trim().length) return '업체명을 입력해주세요.'
      if (!formValues.businessType.trim().length) return '사업 종류를 선택해주세요.'
      if (!formValues.businessNumber.trim().length) return '사업자 번호를 입력해주세요.'
      if (!formValues.address.trim().length) return '주소를 입력해주세요.'
      if (!formValues.website.trim().length) return '홈페이지를 입력해주세요.'
    }
  }

  // 다음 단계로 이동 및 유효성 검사
  const next = async (skipStep) => {
    if (step) {
      setLoading(true)
  
      // 현재 단계 유효성 검사
      const errorMessage = await [validateTerms, validateInfo, validateStep3][step - 1]()
  
      setLoading(false)
  
      // 에러 메세지가 있다면 에러 메세지 출력
      if (errorMessage)
        return alert(errorMessage)
    }

    // skipStep 있다면 skipStep 단계로 바로 이동
    if (skipStep)
      return setStep(skipStep)

    // 3단계라면 회원가입 처리
    // 아니면 다음 단계로 변경
    step === 3 
      ? submit()
      : setStep(step + 1)
  }

  // 회원가입 처리
  const submit = async () => {
  }

  const moveback = () => {
    step ? setStep(step - 1) : setType('in')
  }

  const setRoleNextStep = (value) => {
    setRole(value)
    next()
  }


  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
      {more && (
        <div className="detailModal">
          <div className="content">
            <Text />
          </div>
          <div className="close" onClick={() => setMore(false)}>x</div>
        </div>
      )}


      <div className="signUp">
        <header>
          <h1>Create<br />Account</h1>

          <div className="backButton">
            <img onClick={moveback} src={ArrowLeftIcon} alt="back_icon" />
          </div>

          <div className="stepViewer">
            <div className={clsx(step === 0 && 'active')} />
            <div className={clsx(step === 1 && 'active')} />
            <div className={clsx(step === 2 && 'active')} />
            <div className={clsx(step === 3 && 'active')} />
          </div>
        </header>

        {/* STEP 0 */}
        {step === 0 && (
          <div className="step0">
            <div style={{ color: '#09bbce' }}>
              <Button color="inherit" variant="outlined" size="large" onClick={() => setRoleNextStep('user')} fullWidth>일반 회원</Button>
            </div>

            <Button color="primary" variant="outlined" size="large" onClick={() => setRoleNextStep('pre')} fullWidth>프리랜서</Button>

            <Button color="secondary" variant="outlined" size="large" onClick={() => setRoleNextStep('shop')} fullWidth>다이브샵 & 리조트</Button>
          </div>
        )}

        {/* STEP 1 */}
        {step === 1 && (
          <div className="step1">

            {/* 전체 동의 */}
            <div className="item">
              <Checkbox
                label='전체동의'
                id="check_all"
                checked={Object.values(checked).every(val => val === true)}
                onChange={(e) => {
                  setChecked(Object.keys(checked).reduce((acc, next) => (
                    {...acc, [next]: e.target.checked}
                  ), {}))
                }}
              />

              <p>전체동의는 선택목적에 대한 동의를 포함하고 있으며, 선택목적에 대한 동의를 거부해도 서비스 이용이 가능합니다.</p>
            </div>
            
            {/* 동의 항목 map */}
            {signTerms.map(signTerm => (
              <div className="item" key={signTerm.id}>
                <div className="flex">
                  <Checkbox
                    label={(
                      <div onClick={(e) => {
                        e.preventDefault()
                        signTerm.onClick && signTerm.onClick()
                      }}>
                        {signTerm.label}

                        <mark className={clsx(signTerm.required && 'required')}>
                          ({signTerm.required ? '필수' : '선택'})
                        </mark>
                      </div>
                    )}
                    id={signTerm.id}
                    checked={checked[signTerm.id] === true}
                    onChange={(e) => {
                      setChecked({ ...checked, [signTerm.id]: e.target.checked })
                    }}
                  />
                </div>

                {signTerm.info && (
                  <p>{signTerm.info}</p>
                )}
              </div>
            ))}

            <div className="submitButtonContainer dark">
              <span>Next</span>

              <Fab color="primary" onClick={() => next()}>
                <img src={ArrowRightIcon} alt="icon" />
              </Fab>
            </div>
          </div>
        )}


        {/* STEP 2 */}
        {step === 2 && (
          <div className="step2">
            <form onSubmit={e => { e.preventDefault(); next() }}>
              <div className="field">
                <span>ID</span>

                <TextField
                  className="kr"
                  value={formValues.id}
                  onChange={e => setFormValues({
                    ...formValues,
                    id: e.target.value
                  })}
                  placeholder="5~20자의 영문 소문자/숫자/특수기호"
                  fullWidth
                />
              </div>

              <div className="field">
                <span>Password</span>

                <TextField
                  className="kr"
                  value={formValues.pw}
                  onChange={e => setFormValues({
                    ...formValues,
                    pw: e.target.value
                  })}
                  type="password"
                  placeholder="영문/숫자/기호조합 6자이상"
                  fullWidth
                />
              </div>

              <div className="field">
                <span>Conform Password</span>

                <TextField
                  value={formValues.rpw}
                  onChange={e => setFormValues({
                    ...formValues,
                    rpw: e.target.value
                  })}
                  type="password"
                  placeholder="비밀번호 재입력"
                  fullWidth
                />
              </div>
            </form>

            <div className="submitButtonContainer dark">
              <span>Next</span>

              <Fab color="primary" onClick={() => next()}>
                <img src={ArrowRightIcon} alt="icon" />
              </Fab>
            </div>
          </div>
        )}


        {/* STEP 3 */}
        {step === 3 && (
          <div className="step3">
            <form onSubmit={e => { e.preventDefault(); next() }}>
              {/* 사용자 & 프리랜서 */}
              {role !== 'shop' && (
                <div className="field">
                  <span>Nickname</span>

                  <TextField
                    className="kr"
                    value={formValues.nickname}
                    onChange={e => setFormValues({
                      ...formValues,
                      nickname: e.target.value
                    })}
                    placeholder="서비스 이용시 노출되는 이름입니다"
                    fullWidth
                  />
                </div>
              )}
              
              {/* 다이브샵 & 리조트 */}
              {role === 'shop' && (
                <>
                  <div className="field">
                    <span>업체명</span>

                    <TextField
                      className="kr"
                      value={formValues.companyName}
                      onChange={e => setFormValues({
                        ...formValues,
                        companyName: e.target.value
                      })}
                      placeholder="업체명 입력"
                      fullWidth
                    />
                  </div>

                  <div className="field">
                    <span>사업 종류</span>

                    <TextField
                      className="kr"
                      value={formValues.businessType}
                      onChange={e => setFormValues({
                        ...formValues,
                        businessType: e.target.value
                      })}
                      SelectProps={{
                        displayEmpty: true
                      }}
                      placeholder="사업 종류 선택"
                      fullWidth
                      select
                    >
                      <MenuItem value="">사업 종류 선택</MenuItem>
                      <MenuItem value="diveShop">다이브샵</MenuItem>
                      <MenuItem value="resort">리조트</MenuItem>
                      <MenuItem value="diveShopResort">다이브샵 & 리조트</MenuItem>
                    </TextField>
                  </div>

                  <div className="field">
                    <span>사업자 번호</span>

                    <TextField
                      className="kr"
                      value={formValues.businessNumber}
                      onChange={e => setFormValues({
                        ...formValues,
                        businessNumber: e.target.value
                      })}
                      placeholder="사업자 번호 입력"
                      fullWidth
                    />
                  </div>

                  <div className="field">
                    <span>주소</span>

                    <TextField
                      className="kr"
                      value={formValues.address}
                      onChange={e => setFormValues({
                        ...formValues,
                        address: e.target.value
                      })}
                      placeholder="주소 입력"
                      fullWidth
                    />
                  </div>

                  <div className="field">
                    <span>홈페이지</span>

                    <TextField
                      type="url"
                      className="kr"
                      value={formValues.website}
                      onChange={e => setFormValues({
                        ...formValues,
                        website: e.target.value
                      })}
                      placeholder="홈페이지 입력"
                      fullWidth
                    />
                  </div>
                </>
              )}

              <div className="field">
                <span>Email</span>

                <TextField
                  type="email"
                  className="kr"
                  value={formValues.email}
                  onChange={e => setFormValues({
                    ...formValues,
                    email: e.target.value
                  })}
                  placeholder="이메일 입력"
                  fullWidth
                />
              </div>
              
              {/* 사용자 & 프리랜서 */}
              {role !== 'shop' && (
                <>
                  <div className="field">
                    <span>Birthday</span>

                    <DatePicker
                      autoOk
                      disableFuture
                      animateYearScrolling
                      disableToolbar
                      fullWidth
                      variant="inline"
                      openTo="year"
                      format="YYYY.MM.DD"
                      views={["year", "month", "date"]}
                      value={formValues.birthday}
                      placeholder="생일 선택"
                      onChange={date => setFormValues({
                        ...formValues,
                        birthday: date.format('YYYY.MM.DD')
                      })}
                    />
                  </div>

                  <div className="field">
                    <span>Phone</span>
                    
                    <div className="buttonInputContainer">
                      <TextField
                        type="tel"
                        className="kr"
                        value={formValues.phone}
                        onChange={e => setFormValues({
                          ...formValues,
                          phone: e.target.value
                        })}
                        placeholder="연락처 ‘-’없이 (전송)"
                        fullWidth
                      />

                      <Fab id="SMSAuthButton">
                        <img src={ArrowRightIcon} alt="icon" />
                      </Fab>
                    </div>
                    
                    <div className="buttonInputContainer">
                      <TextField
                        type="tel"
                        className="kr"
                        value={formValues.code}
                        onChange={e => setFormValues({
                          ...formValues,
                          code: e.target.value
                        })}
                        placeholder="인증번호"
                        fullWidth
                      />

                      <Fab>
                        <img src={DoneIcon} alt="icon" />
                      </Fab>
                    </div>
                  </div>

                  <div className="field">
                    <span>다이빙 로그 수</span>

                    <TextField
                      className="kr"
                      value={formValues.divingCount}
                      onChange={e => setFormValues({
                        ...formValues,
                        divingCount: e.target.value
                      })}
                      placeholder={`다이빙 로그 수 입력 ${role === 'user' ? '(선택사항)' : ''}`}
                      fullWidth
                    />
                  </div>
                </>
              )}

              
              {/* 사용자 */}
              {role === 'user' && (
                <>
                  <div className="field">
                    <span>다이빙 선호 지역</span>

                    <TextField
                      className="kr"
                      value={formValues.divingFavorPlace}
                      onChange={e => setFormValues({
                        ...formValues,
                        divingFavorPlace: e.target.value
                      })}
                      placeholder="다이빙 선호 지역 입력 (선택사항)"
                      fullWidth
                    />
                  </div>

                  <div className="field">
                    <span>자격증 정보</span>

                    <TextField
                      className="kr"
                      value={formValues.license}
                      onChange={e => setFormValues({
                        ...formValues,
                        license: e.target.value
                      })}
                      placeholder="자격증 정보 입력 (선택사항)"
                      fullWidth
                    />
                  </div>
                </>
              )}
              
              {/* 프리랜서 */}
              {role === 'pre' && (
                <>
                  <div className="field">
                    <span>다이빙 강사 자격증 번호</span>

                    <TextField
                      className="kr"
                      value={formValues.divingLicenseNumber}
                      onChange={e => setFormValues({
                        ...formValues,
                        divingLicenseNumber: e.target.value
                      })}
                      placeholder="다이빙 강사 자격증 번호 입력"
                      fullWidth
                    />
                  </div>

                  <div className="field">
                    <span>소속 협회</span>

                    <TextField
                      className="kr"
                      value={formValues.association}
                      onChange={e => setFormValues({
                        ...formValues,
                        association: e.target.value
                      })}
                      placeholder="소속 협회 입력"
                      fullWidth
                    />
                  </div>
                </>
              )}
            </form>

            <div className="submitButtonContainer">
              <span>Sign up</span>

              <Fab color="primary" onClick={() => next()}>
                <img src={ArrowRightIcon} alt="icon" />
              </Fab>
            </div>
          </div>
        )}

        {loading ? (
          <div className="paymentLoading">
            <CircularProgress size={30} thickness={6} />
            <span>잠시만 기다려주세요</span>
          </div>
        ) : ''}
      </div>
    </MuiPickersUtilsProvider>
  )
}
