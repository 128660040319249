import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { tourListState } from '~uStores/list/tour'
import { searchKeyword } from '~/stores/ui/searchInput'
import Title from '~ui/title/Title'
import CardList from '~ui/card/CardList'
import './tourList.scss'


const TourList = ({ filterData, search = true }) => {
  const [tourList, reloadTour] = useRecoilState(tourListState)
  const [keyword, setKeyword] = useRecoilState(searchKeyword)
  const [order, setOrder] = useState('추천순')

  const places = filterData?.place || ['전체']
  const list = tourList
    .filter(v => (v.place.includes(keyword) || v.title.includes(keyword)) && (places.includes('전체') ? true : places.includes(v.place)))

  list.sort((a, b) => {
    switch (order) {
      case "추천순":
        return a.id > b.id ? 1 : -1  
      case "신상품순":
        return a.id < b.id ? 1 : -1  
      case "낮은 가격순":
        return a.point > b.point ? 1 : -1  
      case "높은 가격순":
        return a.point < b.point ? 1 : -1  
      case "평점 높은순":
        return a.score < b.score ? 1 : -1  
      default:
        return a.id > b.id ? 1 : -1  
    }
  })

  useEffect(() => {
    setKeyword('')
  }, [])


  return (
    <div className="tourList">
      {search && (
        <Title 
          label={<div>투어 검색결과 - 총 <span>{list.length}</span>개</div>} 
          order={true} 
          changeOrder={(val) => setOrder(val)} 
        />
      )}

      <CardList 
        list={list}
        link="/tours/detail"
        label="검색된 투어가 없습니다."
      />
    </div>
  )
}

export default TourList