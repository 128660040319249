import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import InfiniteGrid, { GridLayout } from '@egjs/infinitegrid'
import { Dialog, Icon } from '@material-ui/core'
import $ from 'jquery'


import './diveShot.scss'
import { DiveShotListState } from '~uStores/list/dive'


const DiveShot = () => {
  const [list, reload] = useRecoilState(DiveShotListState)
  const [open, setOpen] = useState(false)
  const [target, setTarget] = useState('')
  const [imgH, setImgH] = useState(0)

  const cols = () => {
    return localStorage['mobile'] ? 3 : 5
  }

  const move = (dir) => {
    const now = target + dir
    setTarget(now >= list.length ? 0 : (now < 0 ? list.length - 1 : now))
  }

  useEffect(() => {
    const ig = new InfiniteGrid("#diveShotGridLayout", {
      horizontal: false,
    })

    ig.setLayout(GridLayout, {
      margin: 14
    })

    ig.layout(true)
  }, [])


  return (
    <div className="diveShot">
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        className="homeDiveShotDialog"
      >
        <div className="diveShot-slider">
          <div className="btn left" onClick={() => move(-1)}><Icon>keyboard_arrow_left</Icon></div>
          <div className="btn right" onClick={() => move(1)}><Icon>keyboard_arrow_right</Icon></div>

          <div className="list" style={{height: imgH+"px"}}>
            {list.map((v, i) => (
              <img 
                src={v}
                key={i} 
                alt="image" 
                className={i === target ? 'active' : ''}
                ref={(v) => {
                  if (i === target) {
                    setImgH($(v).height())
                  }
                }}
              />
            ))}
          </div>

          <div className="pos">{target+1}/{list.length}</div>
        </div>
      </Dialog>

      <h1>Dive shot</h1>

      <div id="diveShotGridLayout">
        {list.map((v, i) => (
          <img 
            src={v} 
            key={i} 
            alt="image" 
            style={{width: `calc((100% - (16px * ${cols() - 1})) / ${cols()})`}} 
            onClick={() => {
              setTarget(i)
              setOpen(true)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default DiveShot