import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Title from '~ui/title/Title'
import './diveEdu.scss'

import Icon from '~assets/images/triangle_right.png'


const DiveEdu = () => {
  const history = useHistory()
  let [position, setPosition] = useState(0)
  let [m, setM] = useState(0)
  let [parent, setParent] = useState(null)

  const watchMousePosition = (e) => {
    setPosition(e.pageY - parent.offsetTop)
    setM(position / 10)
  }

  const list = [
    { sub: 'OPEN WATER', title: '오픈워터', link: '/dive-edu/open-water' },
    { sub: 'ADVANSED OPEN WATER', title: '어드밴스드 오픈워터', link: '/dive-edu/advanced' },
    { sub: 'RESCUE', title: '레스큐', link: '/dive-edu/rescu' },
    { sub: 'DIVE MASTER', title: '다이브마스터', link: '/dive-edu/dive-master' },
    { sub: 'SPECIAL-T', title: '스페셜티', link: '/dive-edu/special-t' },
    { sub: 'ASSIST INSTRUCT', title: '강사', link: '/dive-edu/instruct' },
  ]

  return (
    <div className="diveEdu">
      <Title label="다이브 교육" link="/" />

      <div className="items" ref={(ref) => {
        setParent(ref)
      }} onMouseMove={watchMousePosition}>
        <div className="position" style={{top: position + 'px'}}>
          {String(m).padStart(2, '0')}m
          <img src={Icon} alt="icon" />
        </div>

        <div className="line_bg"></div>

        {list.map((v, i) => (
          <div key={i} className="item" onClick={() => history.push(v.link)}>
            <div className="sub">
              <em>LEVEL 0{i + 1}</em>{v.sub}
            </div>

            <h2>{v.title}</h2>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DiveEdu