import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import Header from '~uCompon/header/Header'
import TourList from '~uCompon/tour/TourList'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'
import InputSearch from '~ui/inputSearch/InputSearch'
import Footer from '~uCompon/footer/Footer'
import ButtonList from '~ui/buttonList/ButtonList'
import Loading from '~ui/loading/Loading'

import '~uCompon/tour/tour.scss'


const Tour = () => {
  const pointPlace = ['전체', '서울', '제주도', '양양', '독도', '대구']
  const [active, setActive] = useState(['전체'])
  const [loading, setLoading] = useState(true)

  return (
    <div className="TourPage">
      <Header active="투어찾기" />

      <Box className="rap" mt="5rem" mb="1rem">
        <InputSearch placeholder="투어를 찾아 보세요!" />
        <div className="pointPlace">
          <ButtonList 
            list={pointPlace}
            multiple={true}
            changeActive={(val) => {
              setActive(val)
            }}
            dir="left"
          />
        </div>
      </Box>


      <Box className="rap" pb="6rem">
        {loading && (
          <Loading timer={(v) => setLoading(v)} />
        )}
        
        <div className={clsx('fadeAnimation', loading && 'hide')}>
          <TourList filterData={{ place: active }} />
        </div>
      </Box>

      <Footer />

      <MobileMenu />
    </div>
  )
}

export default Tour