import React from 'react'
import { useRecoilState } from 'recoil'
import { Dialog } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import $ from 'jquery'

import './signDialog.scss'
import { signDialogState } from '~/stores/ui/sign'

import SignIn from './SignIn'
import SignUp from './SignUp'

function DialogContent({ type, setType, setOpen }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  React.useEffect(() => {
    if (fullScreen) {
      $('html, body').addClass('signDialogMobileFontSize')
    }

    return () => {
      $('html, body').removeClass('signDialogMobileFontSize')
    }
  }, [fullScreen])

  return (
    <div className="signContainer">
      <div className="sign">
        {type === 'in' && <SignIn setType={setType} setOpen={setOpen} />}
        {type === 'up' && <SignUp setType={setType} setOpen={setOpen} />}
      </div>
    </div>
  )
}

export default function SignDialog() {
  const [open, setOpen] = useRecoilState(signDialogState)
  const [type, setType] = React.useState('in')
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={fullScreen}
      maxWidth="sm"
      className="signDialog"
    >
      <DialogContent type={type} setType={setType} setOpen={setOpen} />
    </Dialog>
  )
}
