import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { useMediaQuery } from 'react-responsive'

import './app.scss'

import UserMainPage from '~uPages/Home'
import PointSearchPage from '~uPages/PointSearch'
import PointSearchMobilePage from '~uPages/PointSearchMobile'
import TourPage from '~uPages/Tour'
import ScubaPage from '~uPages/Scuba'
import ScubaDetailPage from '~uPages/ScubaDetail'
import TourDetail from '~uPages/TourDetail'
import DiveEduDetail from '~uPages/DiveEduDetail'
import DiveEdu from '~uPages/DiveEdu'
import InstrPage from '~/pages/user/Instr'
import InstrDetail from '~/pages/user/InstrDetail'

import Loading from '~ui/loading/Loading'
import SignDialog from '~uCompon/sign/SignDialog'


function AutoScrollTop() {
  const location = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (<></>)
}

function Ddd() {
  const location = useLocation()

  useEffect(() =>  {
    if (location.pathname.includes('point/search')) {
      document.querySelector('body').style.overflow = 'hidden'
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = 'inherit'
      document.querySelector('html').style.overflow = 'inherit'
    }
  }, [location.pathname])

  return (<></>)
}


function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1200px)'
  })

  React.useEffect(() => {
    if (!isDesktopOrLaptop) {
      document.querySelector('html').style.fontSize = '12px'
      document.querySelector('body').style.fontSize = '12px'
      document.querySelector('body').classList.add('mobile')

      localStorage['mobile'] = 'mobile'
    } else {
      localStorage.removeItem('mobile')
    }
  }, [])

  return (
    <RecoilRoot>
      <React.Suspense fallback={<div className="pageLoadingContainer"><Loading /></div>}>
        <BrowserRouter>
          <AutoScrollTop />
          <SignDialog />

          <Ddd />

          <Switch>
            <Route path="/" component={UserMainPage} exact />
            <Route path="/tours" component={TourPage} exact />
            <Route path="/tours/detail/:id" component={TourDetail} exact />
            <Route path="/dive-edu/:type" component={DiveEdu} exact />
            <Route path="/dive-edu/detail/:id" component={DiveEduDetail} exact />
            <Route path="/scuba" component={ScubaPage} exact />
            <Route path="/scuba/detail/:id" component={ScubaDetailPage} exact />
            <Route path="/instr" component={InstrPage} exact />
            <Route path="/instr/detail/:id" component={InstrDetail} exact />
          </Switch>

          {isDesktopOrLaptop ? (
            <Route path="/point/search" component={PointSearchPage} exact />
          ) : (
            <Route path="/point/search" component={PointSearchMobilePage} exact />
          )}
        </BrowserRouter>
      </React.Suspense>
    </RecoilRoot>
  );
}

export default App;
