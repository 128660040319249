import React from 'react'
import { useHistory } from 'react-router-dom'

import './sidebar.scss'
import LogoWhite from '~/assets/images/icon/logo_white.png'
import DefaultProfileImage from '~/assets/images/icon/default_profile_image.png'
import SearchContext from '~/contexts/searchContext'

import Input from '~ui/input/Input'
import List from '~ui/list/List'
import { HorizontalItem } from '~ui/list/ListItem'


export default function SearchSidebar() {
  return (
    <div className="map-sidebar">
      <SearchSidebarHeader />
      <SearchSidebarBody />
    </div>
  )
}

function SearchSidebarHeader() {
  const history = useHistory()
  const {
    state: { keyword },
    changeKeyword
  } = React.useContext(SearchContext)


  return (
    <div className="header">
      <div className="nav" style={{cursor: 'pointer'}}>
        <img src={LogoWhite} alt="로고 이미지" onClick={() => history.push('/')} />
      </div>

      <div className="search">
        <Input
          className="keyword-input"
          theme="shadow"
          adornment={{
            end: {
              type: 'icon',
              icon: 'search'
            }
          }}
          value={keyword}
          onChange={(e) => changeKeyword(e.target.value)}
          fullWidth
        />
        <p className="info">뷰 포인트 검색 서비스</p>
      </div>
    </div>
  )
}

function SearchSidebarBody() {
  const {
    state: { data, keyword },
    getTabData
  } = React.useContext(SearchContext)

  const totalCount = data.length

  const tabData = getTabData()

  const list = tabData.map((item, i) => ({
    id: i,
    image: DefaultProfileImage,
    title: item.name,
    phone: item.pos,
    address: item.address,
    category: item.category || null,
    website: null,
    info: null,
    link: item.link
  }))
  
  return (
    <div className="body">
      <div className="result-text">
        <p className="result">
          <span className="highlight">‘{keyword}’</span>의 검색 결과 총 
          <span className="highlight">{totalCount.toLocaleString()}</span>건
        </p>
        <p className="sort"><span>거리순 노출</span></p>
      </div>
      <div className="result-list">
        <SearchSidebarList items={list} />
      </div>
    </div>
  )
}

function SearchSidebarList({ items = [] }) {
  const {
    state: { activeItem }
  } = React.useContext(SearchContext)

  return (
    <>
      <List direction="vertical">
        {items.map((item, key) => (
          <SearchSidebarListItem
            {...item}
            key={key}
            eachIndex={key}
          />
        ))}
      </List>
    </>
  )
}

function SearchSidebarListItem({ id, image, title, phone, address, website, info, eachIndex, isActive, category, link }) {
  const {
    state: { activeItem },
    changeActiveItem
  } = React.useContext(SearchContext)
  
  return (
    <HorizontalItem
      className={isActive ? 'active' : ''}
      badge={eachIndex + 1}
      image={image}
      title={title}
      info={
        <>
          <p className="line" data-id={id}>
            <span>{address}</span> 
            <span className="highlight">{phone}</span>
          </p>

          <p>
            <a href={link} target="_blank">
              <em>상세보기</em>
            </a>
          </p>
        </>
      }
      onClick={() => changeActiveItem(activeItem === id ? null : id)}
    />
  )
}
