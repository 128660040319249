import React from 'react'

import HomeHeader from '~uCompon/home/Header'
import './header.scss'


const Header = ({ active }) => {
  return (
    <div className="header">
      <div className="rap">
        <HomeHeader type="sub" active={active} />
      </div>
    </div>
  )
}

export default Header