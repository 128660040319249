import { atom, selector } from 'recoil'

import edu1 from '~/assets/images/edu1.jpg'
import edu2 from '~/assets/images/edu2.jpg'
import edu3 from '~/assets/images/edu3.jpg'
import edu4 from '~/assets/images/edu4.jpg'
import edu5 from '~/assets/images/edu5.jpg'
import edu6 from '~/assets/images/edu6.jpg'

import tour1 from '~/assets/images/tour1.png'
import tour2 from '~/assets/images/tour2.png'
import tour3 from '~/assets/images/tour3.png'
import tour4 from '~/assets/images/tour4.png'


import more1 from '~/assets/images/more1.jpg'
import more2 from '~/assets/images/more2.jpg'
import more3 from '~/assets/images/more3.jpeg'
import more4 from '~/assets/images/more4.jpeg'
import more5 from '~/assets/images/more5.jpeg'
import more6 from '~/assets/images/more6.jpg'
import more7 from '~/assets/images/more7.jpg'
import more8 from '~/assets/images/more8.jpg'
import more9 from '~/assets/images/more9.jpeg'
import more10 from '~/assets/images/more10.jpg'
import more11 from '~/assets/images/more11.jpeg'

import a1 from '~/assets/images/a1.jpg'
import a2 from '~/assets/images/a2.jpg'
import a3 from '~/assets/images/a3.jpg'
import a4 from '~/assets/images/a4.jpg'
import a5 from '~/assets/images/a5.jpg'
import a6 from '~/assets/images/a6.jpg'
import a7 from '~/assets/images/a7.jpg'
import a8 from '~/assets/images/a8.jpg'
import a9 from '~/assets/images/a9.jpg'
import a10 from '~/assets/images/a10.jpg'


export const eduListState = selector({
  key: 'eduListState',
  get: async ({ get }) => {
    return [
      {
        id: 1,
        img: edu1,
        images: [tour1, tour2, tour3, tour4, a1, a2, a3],
        place: '필리핀',
        title: '베이직 오픈워터 코스 + 1회 펀다이빙 무료 추가',
        desc: `
          세부는 인천이나 부산에서 4시간이면 도착할 수 있는 가까운 동남아 도시중의 하나이며,
          공항 도착후 숙소까지는 30분 정도면 도착할 정도로 가까운 곳에 숙소들이 위치하고 있어 편하게 다이빙교육을 받을 수 있습니다. 또한 세부는 다양한 수중환경들이 존재하고 있어 스쿠버 다이빙을 즐기고자 하는 초심자들과 중급자들에게 매력적인 장소이기도 합니다. 코스 후 세부와 인접해 있는 보홀이나 모알보알, 두마게티등으로 펀다이빙을 떠날 수도 있어 세부는 다이빙 입문자에게는 여러모로 중요한 요충지이기도 합니다.
          
          오픈워터 교육커리큘럼은
          
          1. 교재 학습
          2. 지식복습 리뷰 및 퀴즈 & 최종시험
          3. 제한수역
          4. 개방수역 4회
          
          이런 순서로 진행이 됩니다. 진행순서는 학생들의 스케줄에 따라서 다소 변경이 될 수 있으나 위 커리큘럼을 생략하는 경우는 없습니다.
          
          개방수역(실제 바다)에서는 1회와 2회 다이빙에서는 5미터와 12미터 사이에서 진행이 되며 3회와 4회는 5미터와 18미터 사이에서 진행이 되게 됩니다. 4회 다이빙 전 최종적으로 18미터까지 다이빙을 하게 되며 실제 바다 환경에서 다양한 스킬들을 배울 수 있어 실제 바다에서 바로 적용할 수 있는 기술을 배우게 됩니다. 또한 이런 스킬들을 반복 숙달함에 따라 자신감이 함께 배양됩니다.
          
          오픈워터 교육은 단독 혹은 다수의 강사님이 함께하는 팀티칭으로 이루어지며 펀다이빙은 오픈워터 수료 후 이루어집니다.
          
          ***안전한 교육이 진행될 수 있게끔 교육은 최대 6인으로만 진행이 되도록 스케쥴을 조정하고 있습니다.
        `,
        cnt: 2,
        score: 4,
        point: '370,000',
        reviews: [
          {
            score: 8,
            goodContent: '정말 너무 잘 가르쳐 주셔서 오래 기억에 남아요!!! 무엇보다 안전에 신경 많이써주시고 안정감이 드는 다이빙이었어요!!',
            badContent: '없어용',
            date: '2020.11.13',
            nickname: '김**'
          },
          {
            score: 7,
            goodContent: '소개받아서 갔는데 친절하고 잘 가르쳐주셨어요. 해양실습도 재밌었고 하길 잘한것같아요',
            badContent: 'x',
            date: '2020.11.12',
            nickname: '이**'
          },
          {
            score: 7.5,
            goodContent: '친절하시고 정확한 교육 감사합니다. 어드밴스도 잘부탁드립니다.',
            badContent: 'x',
            date: '2020.11.15',
            nickname: '박**'
          }
        ]
      },
      {
        id: 2,
        img: edu2,
        images: [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10],
        place: '인도네시아',
        title: '[투어] 롬복 PADI 스킨스쿠버 다이빙 오픈워터 자격증 코스',
        desc: `※ 1인부터 예약 가능
        ※ 본투어는 한국어 또는 영어로 진행됩니다.
        ※ 차량은 다른팀과 조인없는 단독차량입니다.
        ※ 다이빙 다국적 학생들과 조인될수 있습니다.
        ※ 미리 예약하시면 한국인 강사가 교육진행해 드립니다.
        (한국인 강사님 스케줄이 맞으면 가능!) 채팅으로 문의주세요.!
        ※ 한국인 강사님이 강습 불가시 현지인 강사님과 영어로 교육 진행됩니다.!
        ※ 다이빙 이후 최소 18시간 이후에 비행기 탑승이 가능합니다! 항공 스케줄에 유의해주세요~
        
        
        [여행일정]
        1일차
        - 08:30 호텔 또는 리조트 로비에서 다이브 크루 미팅
        - 08:30-10:00 다이브센터로 이동후 이론 교육
        - 10:00-11:30 PADI 제한 수역 다이빙 1(수영장 또는 개방된제한수역)
        - 11:30-13:00 점식식사 비치레스토랑 (개별지불 약 5$)
        - 13:00-14:00 PADI 개방수역 다이빙 1
        - 14:00-15:00 티타임
        - 15:00-16:00 PADI 개방수역다이빙 2
        - 17:00-18:00 지식복습
        - 18:00 호텔 드랍 자유일정
        
        2일차
        - 08:30 호텔 또는 리조트 로비에서 다이브 크루 미팅
        - 08:00 - 10:00 다이브 사이트로 이동
        - 10:00 - 11:00 개방수역 다이빙 3
        - 11:00 - 12:00 중식 비치레스토랑 (개별지불 약 5$)
        - 12:00 - 13:00 개방수역 다이빙 4
        - 13:00 - 14:30 다이브 센터로 이동
        - 14:30 - 15:30 오픈워터 시험
        - 15:30 - 16:00 오픈워터 수료 - 호텔드랍`,
        cnt: 5,
        score: 3,
        point: '120,000',
        reviews: [
          {
            score: 7,
            goodContent: '물에 떠 있는 것도 어려워서 잘 할 수 있을지 걱정했는데, 체계적으로 잘 알려주셔서 좋았습니다. 덕분에 level2까지 성공했어요ㅎㅎ 안전을 중요시하셔서 배우고 도전하는데 안심할 수 있었습니다. 감사합니다~',
            badContent: 'x',
            date: '2020.11.20',
            nickname: '임**'
          },
          {
            score: 8,
            goodContent: '여러가지 원데이클래스를 배우고 여러강사님들을 만났는데 그분들중에 가장 친절하였다고 생각합니다. 개인적인 상황으로 일정을 하루전에 바꾸었는데도 아무걱정말라면서 친절하게 응대해주고 수업내내 여자친구에 비해 잘못하는데도 할수있다고 이끌어주시고 감사합니다!',
            badContent: 'x',
            date: '2020.11.17',
            nickname: '승**'
          }
        ]
      },
      {
        id: 3,
        img: edu3,
        images: [more1, more2, more3, more4, more5, more6, more7, more8, more9, more10, more11],
        place: '팔라우',
        title: '팔라우 오픈워터 자격증 취득코스',
        desc: `팔라우 오픈워터 자격증 취득코스
        팔라우는 3종류의 해류가 팔라우를 지나가고 있으며 환상적인 다이빙 포인트로
        세계 최고의 포인트 1위의 자리에 있습니다.
        다이버들의 성지 또는 신들의 바다정원이라 불리고 있습니다.
        팔라우는 580개의 산호초 섬으로 이루어진 남태평양 팔라우 공화국 (Republic of Palau) 독립국가 입니다..
        
        팔라우의 풍부하고 훼손되지 않은 산호초는 해양생물의 좋은 서식지인 동시에 700여종의 산호의 고향이며,
        그 중에 400여종은 경산호입니다.
        최근까지 밝혀진 바에 의하면 1500여종의 매혹적이며 화려하게 치장된 어류들이 서식하고 있다고 합니다.
        팔라우의 따뜻한 열대바다 (29도)는 마이크로네시아의 그 어떤 곳보다 다양한 해양생물이 서식하고 있고,
        이에 따라서 200여 곳의 다이빙 포인트와 60여 곳의 난파선 포인트 등
        아주 다양하고 많은 다이빙 포인트를 가지고 있습니다.
        대표 생물로는 최대길이 6~7미터 1,500kg의 만타 가오리를 보실 수 있는 곳입니다.`,
        cnt: 3,
        score: 4,
        point: '600,000',
        reviews: [
          {
            score: 9,
            goodContent: '즐거운 분위기속에서 강습받는 인원들 한명한명 세심하게 알려주시고 각자 잘안되는 부분까지 지켜봐주시면서 고쳐주시려고 노력해주셨어요! 강사님 덕분에 매우 즐겁게 자격 취득할수 있었고 프리다이빙의 매력을 더 크게 받아들일 수 있는 시간이었던 것 같습니다ㅎㅎ많은 분들이 강사님을 통해 다이빙의 매력에 빠지는 계기가 되었으면 좋겠습니다.',
            badContent: 'xx',
            date: '2020.11.10',
            nickname: '안**'
          },
          {
            score: 10,
            goodContent: '생전 처음 하는 다이빙이었는데 강사님께서 눈높이에 맞춰서 잘 가르쳐주십니다!!',
            badContent: 'x',
            date: '2020.11.11',
            nickname: '김**'
          },
          {
            score: 9,
            goodContent: '친절히 잘 설명해 주셔서 감사합니다. 좋은 체험이 되었어요.',
            badContent: 'x',
            date: '2020.11.11',
            nickname: '황**'
          },
        ]
      },
    ]
  }
})