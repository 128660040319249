import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dialog, Icon } from '@material-ui/core'

import logo from '~assets/images/icon/logo_white.png'
import './footer.scss'


const Footer = () => {
  const [open, setOpen] = useState(false)

  return (
    <footer>
      <div className="links">
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>

        <div className="menu">
          <div onClick={() => alert('준비중입니다')}>ABOUT</div>
          <div onClick={() => alert('준비중입니다')}>TERMS</div>
          <div onClick={() => alert('준비중입니다')}>PRIVACY</div>
          <div onClick={() => setOpen(true)}>FAQ</div>
        </div>
      </div>

      <div className="copyRight">
        COPYRIGHT &copy; 2020 AQUANODE
      </div>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        className="faqDialog"
      >
        <form className="faq" onSubmit={(e) => {
          alert('전송되었습니다.')
          e.preventDefault()
          setOpen(false)
        }}>
          <div className="close" onClick={() => setOpen(false)}>
            <Icon>close</Icon>
          </div>
          <div className="title">
            <h2>FAQ</h2>
            <p>문의 내용을 상세하게 입력해주세요.</p>
          </div>

          <input type="text" name="text" placeholder="연락처를 입력해주세요" required />
          <textarea name="message" rows="9" placeholder="문의내용을 입력해주세요" required></textarea>

          <button>SUBSCRIPT</button>
        </form>
      </Dialog>
    </footer>
  )
}

export default Footer