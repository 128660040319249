import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import Header from '~uCompon/header/Header'
import InstrList from '~/components/user/instr/InstrList'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'
import InputSearch from '~ui/inputSearch/InputSearch'
import Footer from '~uCompon/footer/Footer'
import ButtonList from '~ui/buttonList/ButtonList'
import Loading from '~ui/loading/Loading'

import '~uCompon/tour/tour.scss'
import Callout from '~/components/ui/callout/Callout'


const Instr = () => {
  const pointPlace = ['전체', '서울 다이빙 학원', 'PADI MASTER', '전국 다이빙 학원', '다이브 마스터', 'NaUI']
  const [active, setActive] = useState(['전체'])
  const [loading, setLoading] = useState(true)

  return (
    <div className="TourPage">
      <Header active="강사정보" />

      <Box className="rap" mt="5rem" mb="1rem">
        <Callout>
          당신의 다이빙 정보는&nbsp;<mark>62점</mark>입니다.
        </Callout>

        <InputSearch placeholder="강사정보를 찾아 보세요!" />
        <div className="pointPlace">
          <ButtonList 
            list={pointPlace}
            multiple={true}
            changeActive={(val) => {
              setActive(val)
            }}
            dir="left"
          />
        </div>
      </Box>


      <Box className="rap" pb="6rem">
        {loading && (
          <Loading timer={(v) => setLoading(v)} />
        )}

        <div className={clsx('fadeAnimation', loading && 'hide')}>
          <InstrList filterData={{ place: active }} />
        </div>

        <Callout>
          당신의 점수&nbsp;<mark>62점</mark>에 따른 강사 추천 목록입니다.
        </Callout>
      </Box>

      <Footer />

      <MobileMenu />
    </div>
  )
}

export default Instr