import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import HomeHeader from './Header'
import Slider from './Slider'

import search from "~assets/images/icon/search.png"
import pin from "~assets/images/icon/pin.png"
import weather from "~assets/images/icon/weather.png"
import wind from "~assets/images/icon/wind.png"
import point_search from "~assets/images/icon/point_search.png"
import tour_search from "~assets/images/icon/tour_search.png"
import tour_proposal from "~assets/images/icon/tour_proposal.png"
import instructor from "~assets/images/icon/instructor.png"
import scuba from "~assets/images/icon/scuba.png"

import './visual.scss'
import './mobileVisual.scss'


const Visual = () => {
  const history = useHistory()
  const [time, setTime] = useState('')
  
  useEffect(() => {
    setInterval(() => {
      const dt = new Date()
      
      setTime((dt.getHours()+'').padStart(2, '0') + ':' + (dt.getMinutes()+'').padStart(2, '0'))
    }, 3000)
  }, [])


  return (
    <div className="homeVisual">
      <div className="visual__left">
        <HomeHeader />

        <div className="searchBox">
          <input type="text" name="text" />
          <img src={search} alt="search_icon" />
        </div>
        
        <div className="posInfo">
          <div className="offset">
            <img src={pin} alt="pos" />
            <h4>위치를 확인중입니다..</h4>
          </div>

          <div className="time">{time}</div>

          <div className="weather">
            <img src={weather} alt="weather" />
            <h4>21<span>℃</span></h4>
          </div>

          <div className="wind">
            <img src={wind} alt="weather" />
            <h4>6<span>㎧</span></h4>
          </div>
        </div>

        <div className="quickMenu">
          <Link to="/point/search" className="item">
            <div><img src={point_search} alt="menu" /></div>
            <h2>포인트검색</h2>
          </Link>
          <Link to="tours" className="item">
            <div><img src={tour_search} alt="menu" /></div>
            <h2>투어찾기</h2>
          </Link>
          <div onClick={() => {
            alert('로그인 후 사용가능합니다')
          }} className="item">
            <div><img src={tour_proposal} alt="menu" /></div>
            <h2>투어제안</h2>
          </div>
          <Link to="/instr" className="item">
            <div><img src={instructor} alt="menu" /></div>
            <h2>강사정보</h2>
          </Link>
          <Link to="/scuba" className="item">
            <div><img src={scuba} alt="menu" /></div>
            <h2>스쿠버샵</h2>
          </Link>
        </div>
      </div>
    
      <Slider />
    </div>
  )
}

export default Visual