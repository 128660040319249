import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import Title from '~ui/title/Title'
import './intr.scss'
import './mobileIntr.scss'

import lIcon from '~assets/images/icon/arrow_left_white.png'
import rIcon from '~assets/images/icon/arrow_right_white.png'
import profile from '~assets/images/profile2.png'

import { instrListState } from '~uStores/list/instructor'


const Intr = () => {
  const history = useHistory()
  const [now, setNow] = useState(0)
  const [instrList, reload] = useRecoilState(instrListState)

  const moveSlide = (dir) => {
    const max = instrList.length - 1
    let idx = now + dir
    idx = idx < 0 ? max : (idx > max ? 0 : idx)

    setNow(idx)
  }


  return (
    <div className="midVisual">
      <div className="rap">
        <Title label="추천강사" link="/instr" />
      </div>

      <div className="content">
        <div className="rap">
          {instrList.map((v, i) => (
            <div className={`box ${now === i ? 'active' : ''}`} key={i}>
              <div className="profile">
                <div className="imgBox">
                  <img src={v.profile} alt="profile" />
                </div>
                <h2>{v.nameKr}</h2>
                <p>{v.nameEn}</p>
              </div>
              <div className="info">
                <div>
                  <h4>소속(라이센스)</h4>
                  <p>{v.licence}</p>
                </div>
                <div>
                  <h4>등급</h4>
                  <p>{v.rating}</p>
                </div>
                <div>
                  <h4>경력</h4>
                  <p>{v.career}</p>
                </div>
                <div>
                  <h4>주 활동 포인트</h4>
                  <div className="tag">
                    {v.points.map((v, i) => (<div key={i}>{v}</div>))}
                  </div>
                </div>
              </div>
              <div className="midVisualBtns">
                <div className="slide-bottom">
                  <div className="moreBtn" onClick={() => history.push(`/instr/detail/${v.id}`)}>자세히</div>
                  <div className="slideBtn">
                    <div onClick={() => moveSlide(-1)}><img src={lIcon} alt="slideIcon" /></div>
                    <div onClick={() => moveSlide(1)}><img src={rIcon} alt="slideIcon" /></div> 
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Intr