import React, { useEffect, useState } from 'react'

import './list.scss'


const ButtonList = ({ list = [], multiple = false, activeItem = '', changeActive, dir = "right" }) => {
  const [active, setActive] = useState(multiple ? [list[0]] : activeItem || list[0])

  const change = (name) => {
    let data = [...active]

    if (data.includes(name)) {
      data.splice(data.indexOf(name), 1)
    } else {
      data.push(name)

      if (name === '전체') {
        data = ['전체']
      } else if (data.indexOf('전체') !== -1) {
        data.splice(data.indexOf('전체'), 1)
      }
    }

    data = data.length ? data : ['전체']

    setActive(data)
  }

  useEffect(() => {
    changeActive && changeActive(active)
  }, [active])

  return (
    <div className={`UI-buttonList ${dir}`}>
      {list.map((v, i) => (
        <div 
          key={i}
          className={`item ${multiple ? (active.includes(v) ? 'active' : '') : (active === v ? 'active' : '')}`}
          onClick={() => {
            multiple ? change(v) : setActive(v)
          }}
        >
          {v}</div>
      ))}
    </div>
  )
}

export default ButtonList