import React from 'react'

import './contact.scss'

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault()
    alert('로그인 후 이용해주세요')
  }

  return (
    <div className="contact">
      <div className="rap">
        <div className="left">
          <h2>
            get in touch<br />
            with us.
          </h2>

          <p>사업자명 : (주)아쿠아노드 대표자명 : 최원흠</p>
          <p>팩스 : 02-2179-9410 주소 : 경기도 안산시 상록구 한양대학로55 창업보육센터 533호</p>
          <p>사업자등록번호 : 000-00-00000 [사업자정보확인] 대표전화 : <em>1234-5678</em></p>

          <h3>help@aquanode.com</h3>
        </div>

        <form className="right" onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Name" required />
          <input type="email" name="email" placeholder="Email" required />
          <textarea name="message" rows="4" placeholder="Message" required></textarea>

          <button>SUBSCRIPT</button>
        </form>
      </div>
    </div>
  )
}

export default Contact