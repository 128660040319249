import React from 'react'
import produce from 'immer'
import $ from 'jquery'
import qs from 'qs'

import SearchContext from '~/contexts/searchContext'

import SearchSidebar from '~uCompon/pointSearch/Sidebar'
import SearchMap from '~uCompon/pointSearch/Map'

const delay = 150
const tmpData = [
  {
    name: '백도비치 포인트',
    pos: '동해안',
    address: '강원 고성',
    link: 'http://www.khoa.go.kr/exploreSea/index.html?type=baekdobeach&place=sea1', 
    lat: 38.313367,
    lng: 128.548300
  },
  {
    name: '터널 포인트',
    pos: '동해안',
    address: '강원 양양',
    link: 'http://www.khoa.go.kr/exploreSea/index.html?type=tunnel&place=sea1',
    /* lat: 38.0755111735392, */
    lat: 37.938900,
    lng: 128.815133
  },
  {
    name: '난파선 포인트',
    pos: '동해안',
    address: '강원 강릉',
    link: 'http://www.khoa.go.kr/exploreSea/index.html?type=nanpasunp&place=sea1',
    lat: 37.826233,
    lng: 128.933767
  },
  {
    name: '인공어초 포인트',
    pos: '동해안',
    address: '강원 강릉',
    link: 'http://www.khoa.go.kr/exploreSea/index.html?type=ingongp&place=sea1',
    lat: 37.908250,
    lng: 128.854967
  }
]

let timeoutHandle


export default function PointSearch() {
  const { keyword } = qs.parse(window.location.search)

  const [state, setState] = React.useState({
    data: [],
    keyword: keyword || '',
    activeItem: null,
    useTmpMapCenter: true,
    mapCenter: {
      lat: 0,
      lng: 0
    },
    tmpMapCenter: {
      lat: 0,
      lng: 0
    }
  })

  const init = () => {
    setTimeout(() => {
      $('html, body').css({
        fontSize: '10px'
      })
    }, 100)

    search(state.keyword)
    setMapCenter()
  }

  const search = async (value) => {
    setState(produce(draft => {
      draft.data = tmpData.filter(item => item.name.includes(value))
    }))
  }

  const changeKeyword = (value) => {
    setState(produce(draft => {
      draft.keyword = value
    }))

    clearTimeout(timeoutHandle)

    timeoutHandle = setTimeout(() => {
      search(value)
    }, delay)
  }

  const changeActiveItem = (value, disabledAutoMapCenter = false) => {
    setState(produce(draft => {
      if (value !== null && !disabledAutoMapCenter) {
        const datas = getTabData()

        draft.useTmpMapCenter = true
        draft.mapCenter.lat = 0
        draft.mapCenter.lng = 0
        draft.tmpMapCenter.lat = Number(datas[value].lat)
        draft.tmpMapCenter.lng = Number(datas[value].lng)
      }

      draft.activeItem = value
    }))
  }

  const getTabData = () => {
    return state.data
  }

  const setMapCenter = () => {
    const geocoder = new window.kakao.maps.services.Geocoder()

    geocoder.addressSearch(state.keyword || '강원 고성', (result, status) => {
      if (status === window.kakao.maps.services.Status.OK) {
        setState(produce(draft => {
          draft.mapCenter = {
            lat: Number(result[0].y),
            lng: Number(result[0].x)
          }
        }))
      }
    })
  }

  React.useEffect(() => {
    init()

    return () => {
      $('html, body').css('font-size', '16px')
    }
  }, [])

  React.useEffect(() => {
    const data = getTabData()

    if (data.length && state.keyword.trim() !== '') {
      setState(produce(draft => {
        draft.mapCenter = {
          lat: Number(data[0].lat),
          lng: Number(data[0].lng)
        }
      }))
    }
  }, [state.data])

  React.useEffect(() => {
    if (state.useTmpMapCenter === true) {
      setState(produce(draft => {
        draft.useTmpMapCenter = false
        draft.mapCenter.lat = state.tmpMapCenter.lat
        draft.mapCenter.lng = state.tmpMapCenter.lng
        draft.tmpMapCenter.lat = 0
        draft.tmpMapCenter.lng = 0
      }))
    }
  }, [state.useTmpMapCenter])


  return (
    <SearchContext.Provider value={{
      tmpData,
      state,

      changeKeyword,
      changeActiveItem,
      getTabData
    }}>
      <div className="PointSearchPage">
        <SearchMap />
        <SearchSidebar />
      </div>
    </SearchContext.Provider>
  )
}
