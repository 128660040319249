import React from 'react'
import './callout.scss'

export default function Callout({ children }) {
  return (
    <div className="callout">
      <span className="material-icons">bubble_chart</span> {children}
    </div>
  )
}
