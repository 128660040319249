import React from 'react'
import KakaoMaps from 'tenel-react-kakao-map'
import $ from 'jquery'

import './map.scss'
import SearchContext from '~/contexts/searchContext'


export default function Map({ type="search" }) {
  const [container, setContainer] = React.useState(null)

  const {
    state: { mapCenter, data }
  } = React.useContext(SearchContext)

  React.useEffect(() => {
    $(document).on('click', '.search-marker', e => {
      window.open(e.currentTarget.dataset.link, '_blank')
    })
  }, [])


  return (
    <div ref={(ref) => setContainer(ref)} style={ type === 'search' ? { width: '100vw', height: '100vh' } : {width: '100%', height: '60rem'}}>
      {container
        ? (
          <KakaoMaps.Map
            container={container}
            center={mapCenter}
          >
            {data.map((item, i) => (
              <KakaoMaps.CustomOverlay
                position={{
                  lat: item.lat,
                  lng: item.lng
                }}
                content={`
                  <div class="search-marker" data-link="${item.link}">
                    <div class="num">${i + 1}</div>
                    <div class="name">${item.name}</div>
                  </div>
                `}
              />
            ))}
          </KakaoMaps.Map>
        )
        : <span>로드중...</span>
      }
    </div>
  );
}
