import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import plusIcon from '~assets/images/icon/plus.png'
import './title.scss'
import ButtonList from '~ui/buttonList/ButtonList'


const Title = ({ label, link, order = false, orderData = false, changeOrder }) => {
  const orders = orderData || ['추천순', '신상품순', '낮은 가격순', '높은 가격순', '평점 높은순']
  const [orderActive, setOrderActive] = useState('추천순')

  useEffect(() => {
    changeOrder && changeOrder(orderActive)
  }, [orderActive])

  return (
    <div className="UI-title" style={{paddingLeft: !link ? '0px' : 'auto'}}>
      {label}
      {link ? (
        <Link to={link}>
          <img src={plusIcon} alt="plus_icon" />
        </Link>
      ) : ''}

      {order ? (
        <div className="orders">
          <ButtonList list={orders} changeActive={(item) => setOrderActive(item)} />
        </div>
      ) : ''}
    </div>
  )
}

export default Title