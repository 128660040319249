import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import './header.scss'
import MenuIcon from '~assets/images/icon/menu.png'
import LogoIcon from '~assets/images/icon/logo_black.png'
import MsgIcon from '~assets/images/icon/message.png'
import search from "~assets/images/icon/search.png"
import pin from "~assets/images/icon/pin.png"
import weather from "~assets/images/icon/weather.png"
import wind from "~assets/images/icon/wind.png"
import point_search from "~assets/images/icon/point_search.png"
import tour_search from "~assets/images/icon/tour_search.png"
import tour_proposal from "~assets/images/icon/tour_proposal.png"
import instructor from "~assets/images/icon/instructor.png"
import scuba from "~assets/images/icon/scuba.png"
import my from "~assets/images/icon/my.png"

import { signDialogState } from '~/stores/ui/sign'



const Header = ({ type = "def", active }) => {
  const history = useHistory()
  const setOpen = useSetRecoilState(signDialogState)

  const logout = () => {
    window.localStorage.removeItem('user')
    window.location = '/'
  }
  

  return (
    <div className={`homeHeader ${type === 'sub' ? 'sub' : ''}`}>
      <div className="left">
        {/* <img src={MenuIcon} alt="menu" className="leftMenu" /> */}
        <img src={LogoIcon} alt="logo_black" className="logo" onClick={() => history.push('/')} />
      </div>

      <div className="right">
        {type === 'sub' ? (
          <div className="quickMenu">
            <div onClick={() => window.open('/point/search')} className={`item ${active === '포인트검색' ? 'active' : ''}`}>
              <div><img src={point_search} alt="menu" /></div>
              <h2>포인트검색</h2>
            </div>
            <div onClick={() => history.push("/tours")} className={`item ${active === '투어찾기' ? 'active' : ''}`}>
              <div><img src={tour_search} alt="menu" /></div>
              <h2>투어찾기</h2>
            </div>
            <div onClick={() => {
              alert('로그인 후 사용가능합니다.')
            }} className={`item ${active === '투어제안' ? 'active' : ''}`}>
              <div><img src={tour_proposal} alt="menu" /></div>
              <h2>투어제안</h2>
            </div>
            <div onClick={() => history.push("/instr")} className={`item ${active === '강사정보' ? 'active' : ''}`}>
              <div><img src={instructor} alt="menu" /></div>
              <h2>강사정보</h2>
            </div>
            <div onClick={() => history.push("/scuba")} className={`item ${active === '스쿠버샵' ? 'active' : ''}`}>
              <div><img src={scuba} alt="menu" /></div>
              <h2>스쿠버샵</h2>
            </div>
            <div onClick={() => setOpen(true)} className={`item`}>
              <div><img src={my} alt="menu" /></div>
              <h2>{window.localStorage.getItem('user') ? <div>testuser님 / <span>로그아웃</span></div> : '로그인'}</h2>
            </div>
          </div>
        ) : (
          window.localStorage.getItem('user') ? <h3>testuser님 / <span style={{cursor: 'pointer'}} onClick={logout}>로그아웃</span></h3> : <h3 style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>로그인</h3>
        )}
      </div>
    </div>
  )
}

export default Header