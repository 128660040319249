import React from 'react'
import { TextField, Fab } from '@material-ui/core'

import KakaoIcon from '~/assets/images/icon/kakao.png'
import FacebookIcon from '~/assets/images/icon/facebook.png'
import NaverIcon from '~/assets/images/icon/naver.png'
import ArrowRightIcon from '~/assets/images/icon/sign-arrow-right.png'
import CancelIcon from '~/assets/images/icon/cancel.svg'

export default function SignIn({ setType, setOpen }) {
  const [values, setValues] = React.useState({
    id: '',
    pw: ''
  })

  const changeValue = (key, value) => {
    setValues({
      ...values,
      [key]: value
    })
  }

  const handleSignIn = async () => {
    if (values.id === 'testuser' && values.pw === 'testuser1234') {
      window.localStorage.setItem('user', 'user')
      window.location = '/'
    } else {
      alert('아이디또는 비밀번호를 다시 확인해주세요.')
    }
  }


  return (
    <div className="signIn">
      <header>
        <h1>Welcome<br />Back</h1>

        <div className="backButton">
          <img onClick={() => setOpen(false)} src={CancelIcon} alt="back_icon" />
        </div>
      </header>

      <form onSubmit={e => { e.preventDefault(); handleSignIn() }}>
        <div className="field">
          <span>User ID</span>

          <TextField
            value={values.id}
            onChange={e => changeValue('id', e.target.value)}
            placeholder="Enter User ID"
            fullWidth
          />
        </div>

        <div className="field">
          <span>Password</span>

          <TextField
            value={values.pw}
            onChange={e => changeValue('pw', e.target.value)}
            type="password"
            placeholder="Enter Password"
            fullWidth
          />
        </div>
      </form>

      <div className="providers">
        <h2>Sign in with</h2>

        <img src={KakaoIcon} alt="icon" onClick={() => alert('서비스 준비중입니다.')} />
        <img src={FacebookIcon} alt="icon" onClick={() => alert('서비스 준비중입니다.')} />
        <img src={NaverIcon} alt="icon" onClick={() => alert('서비스 준비중입니다.')} />
      </div>

      <div className="submitButtonContainer">
        <span>Sign in</span>

        <Fab color="primary" onClick={handleSignIn}>
          <img src={ArrowRightIcon} alt="icon" />
        </Fab>
      </div>

      <div className="links">
        <div onClick={() => setType('up')} className="active">
          <span>Sign up</span>
        </div>
        {/* <div onClick={() => history.push('/forget/password')}>Forgot account ?</div> */}
      </div>
    </div>
  )
}
