import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { diveShopListState } from '~uStores/list/dive'
import { searchKeyword } from '~/stores/ui/searchInput'
import Title from '~ui/title/Title'
import CardList from '~ui/card/CardList'


const DiveShopList = ({ filterData, search = true }) => {
  const [shopList, reload] = useRecoilState(diveShopListState)
  const [keyword, setKeyword] = useRecoilState(searchKeyword)
  const [order, setOrder] = useState('추천순')

  const places = filterData?.place || ['전체']
  const list = shopList
    .filter(v => (v.place.includes(keyword) || v.title.includes(keyword)) && (places.includes('전체') ? true : places.includes(v.place)))

  list.sort((a, b) => {
    switch (order) {
      case "추천순":
        return a.id > b.id ? 1 : -1  
        break;
      case "신상품순":
        return a.id < b.id ? 1 : -1  
        break;
      case "낮은 가격순":
        return a.point > b.point ? 1 : -1  
        break;
      case "높은 가격순":
        return a.point < b.point ? 1 : -1  
        break;
      case "평점 높은순":
        return a.score < b.score ? 1 : -1  
        break;   
    }
  })

  useEffect(() => {
    setKeyword('')
  }, [])


  return (
    <div className="diveShopList">
      {search && (
        <Title 
          label={<div>스쿠버샵 검색결과 - 총 <span>{list.length}</span>개</div>} 
          order={true} 
          changeOrder={(val) => setOrder(val)} 
          orderData={['추천순', '신상품순', '평점 높은순']}
        />
      )}

      <CardList 
        list={list}
        link="/scuba/detail"
        label="검색된 스쿠버샵이 없습니다."
      />
    </div>
  )
}

export default DiveShopList