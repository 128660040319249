import React from 'react'

import Card from './Card'
import './cardList.scss'


const CardList = ({ list, link = '', label = '검색된 결과가 없습니다' }) => {
  return (
    <div className="UI-cardList">
      {list.map((v, i) => (
        <Card item={v} key={i} link={link+'/'+i} />
      ))}
      {!list.length ? (
        <div className="notListData">{label}</div>
      ) : ''}
    </div>
  )
}

export default CardList