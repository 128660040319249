import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'

import { diveShopListState } from '../../../stores/user/list/dive'

import leftIcon from '~assets/images/icon/arrow_left_white.png'
import rightIcon from '~assets/images/icon/arrow_right_white.png'


const Slider = () => {
  const history = useHistory()
  const [now, setNow] = useState(0)
  const [diveList, reload] = useRecoilState(diveShopListState)
  const [target, setTarget] = useState()

  const init = () => {
    setTarget(new Swiper(`.visual-slider`)
      .on('slideChange', ({ activeIndex }) => {
        setNow(activeIndex)
      })
    )
  }

  const moveSlide = (type, dir) => {
    let nowId = now
    let max = diveList.length
    
    nowId += dir === 'next' ? 1 : -1
    nowId = nowId < 0 ? 0 : (nowId > max - 1 ? max - 1 : nowId)
    
    setNow(nowId)
    target.slideTo(nowId)
  }

  useEffect(() => {
    init()
  }, [])


  return (
    <div className="visual__right">
      <div className="swiper-container visual-slider">
        <div className="swiper-wrapper">
          {diveList.map((v, i) => (
            <div className="swiper-slide" key={i}>
              <img src={v.img} alt="slider" />
            </div>
          ))}
        </div>
      </div>

      <div className="slideInfo">
        <div className="item">
          <div className="top">
            <div className="slideHeader">
              <h3>투어</h3>
              <div className="slideBar"><div className="progress" style={{width: (now+1)/diveList.length * 100 + '%'}}></div></div>
              <p className="slideIndex">{now+1}<span>/{diveList.length}</span></p>
            </div>
            
            <div className="contentSlider">
              {diveList.map((v, i) => (
                <div key={i}>
                  <div className={`contents ${now === i ? 'active' : ''}`}>
                    <div className="title">{v.title.length > 20 ? v.title.slice(0, 24)+'..' : v.title}</div>
                    
                    <div className="info" style={{wordBreak: 'keep-all'}}>{v.desc.slice(0, 67)+'..'}</div>

                    <div className="list">
                      {[
                        {key: 'cnt', val: '인원', render: val => val+'명'},
                        {key: 'place', val: '장소'},
                        {key: 'phone', val: '전화번호'},
                        {key: 'cost', val: '비용', class: 'cost', render: val => (val*1).toLocaleString()},
                      ].map((item, k) => (
                        <div className={`item ${item.class || ''}`} key={k}>
                          <h3>{item.val}</h3>
                          <p>{item.render ? item.render(v[item.key]) : v[item.key]}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="slide-bottom">
            <div className="moreBtn" onClick={() => history.push(`/tours/detail/${diveList[now].id}`)}>자세히</div>
            <div className="slideBtn">
              <div onClick={() => moveSlide('visual', 'prev')}><img src={leftIcon} alt="slideIcon" /></div>
              <div onClick={() => moveSlide('visual', 'next')}><img src={rightIcon} alt="slideIcon" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider