import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Icon, Box } from '@material-ui/core'
import clsx from 'clsx'

import MarkerIcon from '~/assets/images/icon/marker.svg'
import { tourListState } from '~uStores/list/tour'
import { instrListState } from '~uStores/list/instructor'

import Header from '~uCompon/header/Header'
import Footer from '~uCompon/footer/Footer'
import Review from '~uCompon/review/Review'
import Counter from '~uCompon/counter/Counter'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'
import ImgList from '~uCompon/imgList/ImgList'

import Loading from '~ui/loading/Loading'
import Image from '~ui/image/Image'
import Map from '~ui/map/Map'


import '~uCompon/tour/detail.scss'
import '~uCompon/scuba/scubaDetail.scss'


const InstrDetail = () => {
  const history = useHistory()
  const { id } = useParams()
  const [instrList, reload] = useRecoilState(instrListState)
  const data = instrList.find(v => v.id == id)
  console.log(data.reviews)

  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  
  return (
    <div className="instrDetail scubaDetailPage">
      <Header active="강사정보" />

      {loading && (
        <Box width="100%" display="flex" justifyContent="center">
          <Loading />
        </Box>
      )}

      <div className={clsx('fadeAnimation', loading && 'hide')}>
        <div className="rap container">
          <div className="leftContainer">
            <div className="head">
              <div className="left">
                <div className="starContainer">
                  {Array(data.score).fill().map(() => (
                    <Icon>star</Icon>
                  ))}
                </div>
                <div className="profileBox">
                  <div>
                    <img src={data.profile} alt="img" />
                  </div>
                  <div>
                    <h2>{data.nameKr}</h2>
                    <h3 style={{marginBottom: '0rem'}}>{data.nameEn}</h3>
                  </div>
                </div>
              </div>

              <div className="right" style={{bottom: '.3rem'}}>
                <div className="scoreContainer">
                  <div className="text">
                    <div className="scoreLabel">우수함</div>
                    <div className="reviewCount">{data.reviews.length}개의 이용 후기</div>
                  </div>

                  <div className="scoreBox">8</div>
                </div>
              </div>
            </div>
            
            <div className="detailImgList">
              <ImgList list={data.images} />
            </div>

            <h2 className="sectionTitle">강사 정보</h2>

            <div className="infoItem">
              <h6>소속(라이센스)</h6>

              <div className="content">
                {data.licence}
              </div>
            </div>

            <div className="infoItem">
              <h6>등급</h6>

              <div className="content">
                {data.rating}
              </div>
            </div>

            <div className="infoItem">
              <h6>경력</h6>

              <div className="content">
                {data.career}
              </div>
            </div>

            <div className="infoItem">
              <h6>고용 횟수</h6>

              <div className="content">
                {data.cnt}회
              </div>
            </div>

            <div className="infoItem">
              <h6>직원수</h6>

              <div className="content">
                {data.people}명
              </div>
            </div>
            
            <div className="infoItem">
              <h6>연락 가능 시간</h6>

              <div className="content">
                {data.callTerm}
              </div>
            </div>

            <div className="infoItem">
              <h6>결제 방식</h6>

              <div className="content">
                {data.payType}
              </div>
            </div>

            <div className="infoItem">
              <h6>제공 서비스</h6>

              <div className="content hashList">
                {data.service.map((v, i) => (
                  <div className="item">{v}</div>
                ))}
              </div>
            </div>

            <div className="infoItem">
              <h6>주 활동 포인트</h6>

              <div className="content hashList">
                {data.points.map((v, i) => (
                  <div className="item">{v}</div>
                ))}
              </div>
            </div>

            <h2 className="sectionTitle">후기 {data.reviews.length}</h2>

            <Review list={data.reviews} />
          </div>

          <div className="rightContainer">
            <div className="sideContent">
              <div className="costContainer">
                <div className="cost" style={{marginBottom: '1rem', marginTop: '0rem'}}>
                  <p>해당 강사에게 원하는 서비스의 견적을 받아보세요!</p>
                </div>

                <button onClick={() => {
                  window.localStorage.getItem('user') ? alert('서비스준비중입니다.') : alert('로그인 후 사용 가능합니다.')
                }} className="wishBtn">견적요청하기</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <MobileMenu />
    </div>
  )
}

export default InstrDetail