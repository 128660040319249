import React from 'react'

import './review.scss'
import { getScoreLabel } from '~/utils'

const items = [
  {
    score: 3,
    goodContent: '',
    badContent: '',
    date: '2020.11.15',
    nickname: 'Kwangill'
  },
  {
    score: 5,
    goodContent: '',
    badContent: '',
    date: '2020.11.14',
    nickname: 'Junyiung'
  },
  {
    score: 9,
    goodContent: '아주 깨끗하고 만족스럽습니다. 조식도 나쁘지 않고 여러모로 편리하고 청결한 곳입니다.',
    badContent: '엘리베이터 운행시 잡음이 심하여 불안감을 주네요.',
    date: '2020.11.13',
    nickname: 'Dongwoo'
  },
  {
    score: 8,
    goodContent: '',
    badContent: '',
    date: '2020.11.11',
    nickname: 'Khail'
  },
  {
    score: 7,
    goodContent: '',
    badContent: '',
    date: '2020.11.09',
    nickname: '홍길동'
  }
]

export default function Review({ list }) {
  return (
    <div className="reviewContainer">
      <div className="reviewList">
        {(list || items).map((item, i) => (
          <div className="item" key={i}>
            <div className="head">
              <div className="scoreBox">{item.score}</div>
              <div className="scoreLabel">{getScoreLabel(item.score)}</div>
            </div>
            
            <div className="content">
              {item.goodContent && (
                <div className="good">
                  <div className="icon" />
                  <p>{item.goodContent}</p>
                </div>
              )}

              {item.badContent && (
                <div className="bad">
                  <div className="icon" />
                  <p>{item.badContent}</p>
                </div>
              )}
            </div>

            <div className="foot">
              {item.date} · 대한민국 · {item.nickname}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
