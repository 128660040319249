import React, { useEffect } from 'react'
import InfiniteGrid, { GridLayout } from '@egjs/infinitegrid'
import $ from 'jquery'

import './imgList.scss'


const ImgList = ({ list }) => {
  const cols = () => {
    return localStorage['mobile'] ? 3 : 3
  }

  useEffect(() => {
    $('#detailList img').css('height', $('#detailList img').eq(0).width()+'px')
  }, [])

  return (
    <div className="UI-imgList">
      <div id="detailList">
        {list.map((v, i) => (
          <img src={v} key={i} alt="image" />
        ))}
      </div>
    </div>
  )
}

export default ImgList