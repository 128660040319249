import React from 'react'
import { useHistory } from 'react-router-dom'

import './card.scss'
import star_active from '~assets/images/icon/star_active.png'
import star from '~assets/images/icon/star.png'


const Card = ({ item, link = '' }) => {
  const history = useHistory()


  return (
    <div className="UI-cardItem" onClick={() => history.push(link)}>
      <div className="imgBox">
        <img src={item.img} alt="img" />
      </div>

      <div className="content">
        <h2 className="place">{item.place}</h2>
        <div className="title">
          {item.title} <span className="cnt">[{item.cnt}]</span>
        </div>

        <div className="btm">
          <div className="score">
            {Array(item.score).fill(0).map((v, i) => (
              <div key={i} className="item">
                <img src={star_active} alt="" />
              </div>
            ))}
            {Array(5 - item.score).fill(0).map((v, i) => (
              <div key={i} className="item">
                <img src={star} alt="" />
              </div>
            ))}
          </div>
          {item.point && (
            <div className="point" v-if="item.point">
              {item.point}<span>/1p</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card