import React from 'react'

import './checkbox.scss'
import TourSearchIcon from '~/assets/images/icon/tour_search.png'
import DoneIcon from '~/assets/images/icon/sign-done.png'


export default function Checkbox({ id, label, className = '', isRadio, inputRef = null, ...rest}) {
  return (
    <div className={`checkbox ${className}`}>
      <input type={isRadio === true ? 'radio' : 'checkbox'} id={id} hidden {...rest} {...(inputRef ? {ref: inputRef} : {})} />

      <label htmlFor={id}>
        <div className="iconBox">
          <span />
          <img src={DoneIcon} />
        </div>

        {label}
      </label>
    </div>
  )
}
