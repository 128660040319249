import { atom, selector } from 'recoil'

import slideImg1 from "~assets/images/slide1.png"
import slideImg2 from "~assets/images/dive_shot10.png"
import slideImg3 from "~assets/images/dive_shot11.png"
import slideImg4 from "~assets/images/dive_shot3.png"
import slideImg5 from "~assets/images/dive_shot6.png"

import dive1 from '~/assets/images/dive1.png'
import dive2 from '~/assets/images/dive2.png'
import dive3 from '~/assets/images/dive3.png'
import dive4 from '~/assets/images/dive4.png'

import dive_shot1 from "~/assets/images/dive_shot1.png"
import dive_shot2 from "~/assets/images/dive_shot2.png"
import dive_shot3 from "~/assets/images/dive_shot3.png"
import dive_shot4 from "~/assets/images/dive_shot4.png"
import dive_shot5 from "~/assets/images/dive_shot5.png"
import dive_shot6 from "~/assets/images/dive_shot6.png"
import dive_shot7 from "~/assets/images/dive_shot7.png"
import dive_shot8 from "~/assets/images/dive_shot8.png"
import dive_shot9 from "~/assets/images/dive_shot9.png"
import dive_shot10 from "~/assets/images/dive_shot10.png"
import dive_shot11 from "~/assets/images/dive_shot11.png"
import dive_shot12 from "~/assets/images/dive_shot12.png"
import dive_shot13 from "~/assets/images/dive_shot13.png"
import dive_shot14 from "~/assets/images/dive_shot14.png"
import dive_shot15 from "~/assets/images/dive_shot15.png"
import dive_shot16 from "~/assets/images/dive_shot16.png"
import dive_shot17 from "~/assets/images/dive_shot17.png"


export const diveListState = selector({
  key: 'diveListState',
  get: async () => {
    return [
      {
        img: slideImg1,
        title: '속초 00포인트',
        content: '스킨스쿠버 전문 동호회 JM다이브는\n 6월 13일부터 14일까지 고성에서 개해제 행사를 진행했다고 밝혔다.',
        cnt: '약 10명',
        place: '00호스텔',
        term: '06.01~06.03(2박 3일)',
        cost: '160,000'
      },
      {
        img: slideImg2,
        title: '속초 00포인트',
        content: '스킨스쿠버 전문 동호회 JM다이브는\n 6월 13일부터 14일까지 고성에서 개해제 행사를 진행했다고 밝혔다.',
        cnt: '약 10명',
        place: '00호스텔',
        term: '06.01~06.03(2박 3일)',
        cost: '160,000'
      },
      {
        img: slideImg3,
        title: '속초 00포인트',
        content: '스킨스쿠버 전문 동호회 JM다이브는\n 6월 13일부터 14일까지 고성에서 개해제 행사를 진행했다고 밝혔다.',
        cnt: '약 10명',
        place: '00호스텔',
        term: '06.01~06.03(2박 3일)',
        cost: '160,000'
      },
      {
        img: slideImg4,
        title: '속초 00포인트',
        content: '스킨스쿠버 전문 동호회 JM다이브는\n 6월 13일부터 14일까지 고성에서 개해제 행사를 진행했다고 밝혔다.',
        cnt: '약 10명',
        place: '00호스텔',
        term: '06.01~06.03(2박 3일)',
        cost: '160,000'
      },
      {
        img: slideImg5,
        title: '속초 00포인트',
        content: '스킨스쿠버 전문 동호회 JM다이브는\n 6월 13일부터 14일까지 고성에서 개해제 행사를 진행했다고 밝혔다.',
        cnt: '약 10명',
        place: '00호스텔',
        term: '06.01~06.03(2박 3일)',
        cost: '160,000'
      },
    ]
  }
})


export const diveShopListState = selector({
  key: 'diveShopListState',
  get: async () => {
    return [
      {
        id: 0,
        img: dive1,
        place: '제주도',
        title: '제주도 감성 다이브센터 율랜드다이브샵',
        cnt: 5,
        score: 4,
        phone: '010-8479-2781',
        cost: 160000,
        lat: 38.3805924324305,
        lng: 128.467866303854,
        desc: '제주 현지인 강사부부가 운영하는 감성 다이브센터 율랜드입니다. 1일 최대 3인 소수 인원으로 진행하는 퀄리티 높은 다이빙 교육과 현지인이 진행하는 세심한 브리핑 및 수중가이드로 펀다이빙을 진행합니다. 멋진 수중사진과 전 스탭 친절하고 안전한 서비스로 모든 다이버들의 편안함을 제공 해 드립니다.',
        reviews: [
          {
            score: 10,
            goodContent: '재밋어요~~ 한번쯤 경험해볼만 한 것 같아요!',
            date: '2020.08.26',
            nickname: '최**'
          },
          {
            score: 9,
            goodContent: `미리 예약하고 가면 예약확인 해주시고 간단한 설명 해주시는데 친절하고 항시 사고대비 대기중이라 안심하고 즐기기 완전 좋아요~~`,
            date: '2020.07.02',
            nickname: '김**'
          },
          {
            score: 6,
            goodContent: `바로 이용할 수 있어서 좋았습니다^^~`,
            date: '2020.04.22',
            nickname: '이**'
          },
        ],
      },
      {
        id: 1,
        img: dive2,
        place: '양양',
        title: '양양 서피비치 투어/(대한민국/양양) PKG',
        cnt: 2,
        score: 2,
        phone: '010-4930-1129',
        cost: 153000,
        lat: 38.0755111735392,
        lng: 128.619079851708,
        desc: '양양 기사문항에 위치한 스쿠버다이빙 리조트입니다. 기사문항 바로 앞에 위치한 저의 리조트는 15인승, 12인승 보트 두척을 보유하고 있으며, 조도(거북섬)를 중심으로 5미터 수심부터 30미터 수심까지 다양한 다이빙 포인트로 다이버분들을 모시고 있습니다.',
        reviews: [
          {
            score: 10,
            goodContent: '스노큘링도 너무 친절하게 알려주시고 패딩보드도 처음이었는데 너무 잘알려주셔서 완전 잘 배웠어요.',
            date: '2020.08.23',
            nickname: '김**'
          },
          {
            score: 6,
            date: '2020.07.05',
            nickname: '김**'
          },
          {
            score: 4,
            date: '2020.06.24',
            nickname: '이**'
          },
        ],
      },
      {
        id: 2,
        img: dive3,
        place: '제주도',
        title: '제주도 다이빙의 메카! 성산 블루버블 다이브샵',
        cnt: 6,
        score: 4,
        phone: '010-5903-5033',
        cost: 200000,
        lat: 37.7521080804166,
        lng: 128.875906144832,
        desc: '제주도 다이빙의 메카 성산 블루버블 리조트입니다. 오래전부터 스쿠버 다이빙의 메카로 사랑 받고 있는 제주도. 그중 핫플레이스라고 할 수 있는 성산 지역에 자리잡고 있습니다.',
        reviews: [
          {
            score: 10,
            goodContent: '수트가 부력이좋아서 물에 둥둥뜨고 체온유지도되요 너무재미있었어요!',
            date: '2020.08.23',
            nickname: '유**'
          },
          {
            score: 9,
            goodContent: `사장님 친절하시고 태워주시고 안내해 주시는 분도 친절하시고 좋았습니다.`,
            date: '2020.07.02',
            nickname: '김**'
          },
          {
            score: 7,
            goodContent: `월정리 바다가 좀 더 색이 예쁘고 이곳은 투명보다는 일반 카약을 넓고 자유롭게 타기 좋은 것 같아요.`,
            date: '2020.05.24',
            nickname: '이**'
          },
        ],
      },
      {
        id: 3,
        img: dive4,
        place: '푸에르토',
        title: '푸에르토 갈레라 2박 3일 천상의 다이빙 포인트로 떠나자!',
        cnt: 3,
        score: 3,
        phone: '010-1474-1231',
        cost: 100000,
        lat: 37.7921080804166,
        lng: 128.875906144832,
        desc: '양양 기사문항에 위치한 스쿠버다이빙 리조트입니다. 기사문항 바로 앞에 위치한 저의 리조트는 15인승, 12인승 보트 두척을 보유하고 있으며, 조도(거북섬)를 중심으로 5미터 수심부터 30미터 수심까지 다양한 다이빙 포인트로 다이버분들을 모시고 있습니다.',
        reviews: [
          {
            score: 7,
            goodContent: '날씨가 흐려서 노을이 조금 아쉬웠지만 그럼에도 너무 즐거웠습니다',
            date: '2020.07.23',
            nickname: '이**'
          },
          {
            score: 9,
            goodContent: `조금 흐린 날씨여서 그런지, 예약자가 저희밖에 없어서 단독투어처럼 즐겼어요. 처음으로 낚시도 해보고 정말 즐거운 시간이었습니다.`,
            date: '2020.07.08',
            nickname: '김**'
          },
          {
            score: 7,
            goodContent: `수트 현장에서 추가금액내고 스노쿨링과 카약했어요 스노쿨링 꼭하세요!`,
            badContent: '온수가 안나와서 샤워하는데 추웠어요ㅠ',
            date: '2020.05.24',
            nickname: '이**'
          },
        ],
      }
    ]
  }
})


export const DiveShotListState = selector({
  key: 'DiveShotListState',
  get: async () => {
    return [
      dive_shot1,
      dive_shot2,
      dive_shot3,
      dive_shot4,
      dive_shot5,
      dive_shot6,
      dive_shot7,
      dive_shot8,
      dive_shot9,
      dive_shot10,
      dive_shot11,
      dive_shot12,
      dive_shot13,
      dive_shot14,
      dive_shot15,
      dive_shot16,
      dive_shot17
    ]
  }
})

export const diveShopListFilterState = atom({
  key: 'diveShopListFilterState',
  default: ''
})