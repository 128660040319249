import { atom, selector } from 'recoil'

import profile from '~assets/images/profile2.png'
import profile2 from '~assets/images/profile3.png'
import profile3 from '~assets/images/profile5.jpg'

import tour1 from '~/assets/images/tour1.png'
import tour2 from '~/assets/images/tour2.png'
import tour3 from '~/assets/images/tour3.png'
import tour4 from '~/assets/images/tour4.png'


import more1 from '~/assets/images/more1.jpg'
import more2 from '~/assets/images/more2.jpg'
import more3 from '~/assets/images/more3.jpeg'
import more4 from '~/assets/images/more4.jpeg'
import more5 from '~/assets/images/more5.jpeg'
import more6 from '~/assets/images/more6.jpg'
import more7 from '~/assets/images/more7.jpg'
import more8 from '~/assets/images/more8.jpg'
import more9 from '~/assets/images/more9.jpeg'
import more10 from '~/assets/images/more10.jpg'
import more11 from '~/assets/images/more11.jpeg'

import a1 from '~/assets/images/a1.jpg'
import a2 from '~/assets/images/a2.jpg'
import a3 from '~/assets/images/a3.jpg'
import a4 from '~/assets/images/a4.jpg'
import a5 from '~/assets/images/a5.jpg'
import a6 from '~/assets/images/a6.jpg'
import a7 from '~/assets/images/a7.jpg'
import a8 from '~/assets/images/a8.jpg'
import a9 from '~/assets/images/a9.jpg'
import a10 from '~/assets/images/a10.jpg'


export const instrListState = selector({
  key: 'instrListState',
  get: async () => {
    return [
      {
        id: 0,
        profile: profile,
        images: [tour1, tour2, tour3, tour4, a1, a2, a3],
        nameKr: '이웅재',
        nameEn: 'Woong-Jae, Lee',
        licence: '다이브 마스터',
        rating: '인스트럭터',
        career: '12년',
        cnt: 48,
        score: 5,
        callTerm: '오전 10시 - 오후 7시 30분',
        payType: '계좌이체, 현금결제 모두 가능',
        points: ['#스쿠버다이빙','#고수','#제주도'],
        service: ['스쿠버다이빙 강습', '수영 강습', '스노클링 강습'],
        people: 4,
        reviews: [
          {
            score: 8,
            goodContent: '정말 너무 잘 가르쳐 주셔서 오래 기억에 남아요!!! 무엇보다 안전에 신경 많이써주시고 안정감이 드는 다이빙이었어요!!',
            badContent: '없어용',
            date: '2020.11.13',
            nickname: '김**'
          },
          {
            score: 7,
            goodContent: '소개받아서 갔는데 친절하고 잘 가르쳐주셨어요. 해양실습도 재밌었고 하길 잘한것같아요',
            badContent: 'x',
            date: '2020.11.12',
            nickname: '이**'
          },
          {
            score: 7.5,
            goodContent: '친절하시고 정확한 교육 감사합니다. 어드밴스도 잘부탁드립니다.',
            badContent: 'x',
            date: '2020.11.15',
            nickname: '박**'
          }
        ]
      },
      {
        id: 1,
        profile: profile2,
        images: [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10],
        nameKr: '정지안',
        nameEn: 'Ji-an, Jeong',
        licence: 'NaUI',
        rating: '인스트럭터',
        career: '15년',
        cnt: 124,
        score: 4,
        callTerm: '오전 4시 - 오후 4시',
        payType: '계좌이체, 현금결제 모두 가능',
        points: ['#서울','#보라카이','#다이빙리조트'],
        service: ['스쿠버다이빙 강습', '프리다이빙 강습', '스노클링 강습'],
        people: 6,
        reviews: [
          {
            score: 7,
            goodContent: '물에 떠 있는 것도 어려워서 잘 할 수 있을지 걱정했는데, 체계적으로 잘 알려주셔서 좋았습니다. 덕분에 level2까지 성공했어요ㅎㅎ 안전을 중요시하셔서 배우고 도전하는데 안심할 수 있었습니다. 감사합니다~',
            badContent: 'x',
            date: '2020.11.20',
            nickname: '임**'
          },
          {
            score: 8,
            goodContent: '여러가지 원데이클래스를 배우고 여러강사님들을 만났는데 그분들중에 가장 친절하였다고 생각합니다. 개인적인 상황으로 일정을 하루전에 바꾸었는데도 아무걱정말라면서 친절하게 응대해주고 수업내내 여자친구에 비해 잘못하는데도 할수있다고 이끌어주시고 감사합니다!',
            badContent: 'x',
            date: '2020.11.17',
            nickname: '승**'
          }
        ]
      },
      {
        id: 2,
        profile: profile3,
        images: [more1, more2, more3, more4, more5, more6, more7, more8, more9, more10, more11],
        nameKr: '김민지',
        nameEn: 'Min-ji, Gim',
        licence: 'PADI MASTER',
        rating: '마스터강사',
        career: '2년',
        cnt: 189,
        score: 5,
        callTerm: '오전 1시 - 오후 8시',
        payType: '계좌이체, 현금결제 모두 가능',
        points: ['#서울','#개인코칭','#마스터강사'],
        service: ['스쿠버다이빙 강습', '수영 강습', '프리다이빙 강습', '스노클링 강습'],
        people: 3,
        reviews: [
          {
            score: 9,
            goodContent: '즐거운 분위기속에서 강습받는 인원들 한명한명 세심하게 알려주시고 각자 잘안되는 부분까지 지켜봐주시면서 고쳐주시려고 노력해주셨어요! 강사님 덕분에 매우 즐겁게 자격 취득할수 있었고 프리다이빙의 매력을 더 크게 받아들일 수 있는 시간이었던 것 같습니다ㅎㅎ많은 분들이 강사님을 통해 다이빙의 매력에 빠지는 계기가 되었으면 좋겠습니다.',
            badContent: 'xx',
            date: '2020.11.10',
            nickname: '안**'
          },
          {
            score: 10,
            goodContent: '생전 처음 하는 다이빙이었는데 강사님께서 눈높이에 맞춰서 잘 가르쳐주십니다!!',
            badContent: 'x',
            date: '2020.11.11',
            nickname: '김**'
          },
          {
            score: 9,
            goodContent: '친절히 잘 설명해 주셔서 감사합니다. 좋은 체험이 되었어요.',
            badContent: 'x',
            date: '2020.11.11',
            nickname: '황**'
          },
        ]
      }
    ]
  }
})