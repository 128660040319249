import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Icon, Box } from '@material-ui/core'
import clsx from 'clsx'

import MarkerIcon from '~/assets/images/icon/marker.svg'
import { tourListState } from '~uStores/list/tour'
import { getScoreLabel } from '~/utils'
import { eduListState } from '~uStores/list/edu'

import Header from '~uCompon/header/Header'
import Footer from '~uCompon/footer/Footer'
import Review from '~uCompon/review/Review'
import Counter from '~uCompon/counter/Counter'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'

import Loading from '~ui/loading/Loading'
import Image from '~ui/image/Image'
import Map from '~ui/map/Map'
import ImgList from '~uCompon/imgList/ImgList'

import '~uCompon/tour/detail.scss'
import '~uCompon/scuba/scubaDetail.scss'


const TourDetail = () => {
  const history = useHistory()
  const [eduList, reload] = useRecoilState(eduListState)
  const { id } = useParams()
  const data = eduList[id]
  const [loading, setLoading] = React.useState(true)
  const [list, setList] = useState([
    /* {
      title: "스킨스쿠버 체험",
      desc: "남녀노소 누구나 수영을 못해도 가능합니다.",
      cost: "238400",
      cnt: 0,
    },
    {
      title: "스킨스쿠버 체험",
      desc: "남녀노소 누구나 수영을 못해도 가능합니다.",
      cost: "74500",
      cnt: 0,
    },
    {
      title: "스킨스쿠버 체험",
      desc: "남녀노소 누구나 수영을 못해도 가능합니다.",
      cost: "12000",
      cnt: 0,
    }, */
  ])

  const reviewCount = data.reviews.length
  const reviewScoreAvg = Number((data.reviews.reduce((acc, next) => acc + next.score, 0) / reviewCount).toFixed(1).replace(/\.0$/, '')) || 0

  
  return (
    <div className="tourDetail scubaDetailPage">
      <Header />

      {loading && (
        <Box width="100%" display="flex" justifyContent="center">
          <Loading timer={v => setLoading(v)} />
        </Box>
      )}
      
      <div className={clsx('fadeAnimation', loading && 'hide')}>
        <div className="rap container">
          <div className="leftContainer">
            <div className="head">
              <div className="left">
                <div className="starContainer">
                  {Array(data.score).fill().map(() => (
                    <Icon>star</Icon>
                  ))}
                </div>

                <h2>{data.title}</h2>
                <h3>{data.place}</h3>

                <span>
                  <img alt="icon" src={MarkerIcon} />
                  {data.place}
                </span>
              </div>

              <div className="right">
                <div className="scoreContainer">
                  <div className="text">
                    <div className="scoreLabel">{getScoreLabel(reviewScoreAvg)}</div>
                    <div className="reviewCount">{reviewCount.toLocaleString()}개의 이용 후기</div>
                  </div>

                  <div className="scoreBox">{reviewScoreAvg}</div>
                </div>
              </div>
            </div>
            
            <ImgList list={data.images} />

            <h2 className="sectionTitle">투어 정보</h2>

            <div className="infoItem">
              <h6>문의 전화번호</h6>

              <div className="content">
                {data.phone}
              </div>
            </div>

            <div className="infoItem">
              <h6>소개</h6>

              <div className="content">
                {data.desc}
              </div>
            </div>

            <h2 className="sectionTitle">후기 {data.reviews.length}</h2>

            <Review list={data.reviews} />
          </div>

          <div className="rightContainer">
            <div className="sideContent">
              <div className="costContainer">
                <div className="cost" style={{marginTop: '-.3rem'}}>
                  <p>총 금액</p>
                  <div>{data.point}<span>원</span></div>
                </div>

                <button onClick={() => {
                  window.localStorage.getItem('user') ? alert('서비스준비중입니다.') : alert('로그인 후 사용 가능합니다.')
                }}>결제하기</button>

                <button onClick={() => {
                  window.localStorage.getItem('user') ? alert('서비스준비중입니다.') : alert('로그인 후 사용 가능합니다.')
                }} className="wishBtn">견적요청하기</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <MobileMenu />
    </div>
  )
}

export default TourDetail