import React from 'react'

import './searchResultList.scss'
import DefaultProfileImage from '~/assets/images/icon/default_profile_image.png'

import SearchContext from '~/contexts/searchContext'

import List from '~ui/list/List'
import { HorizontalItem } from '~ui/list/ListItem'


export default function SearchResultList() {
  const {
    state: { keyword },
    getTabData
  } = React.useContext(SearchContext)

  const tabData = getTabData()

  const list = tabData.map((item, i) => ({
    id: i,
    image: DefaultProfileImage,
    title: item.name,
    phone: item.pos,
    address: item.address,
    category: item.category || null,
    website: null,
    info: null,
    link: item.link
  }))

  return (
    <div className="mobile-searchResultList">
      <div className="info">
        <p className="description">
          <em>‘{keyword}’</em>의 검색 결과 총
          <em>{list.length.toLocaleString()}</em>건
        </p>
        <p className="sort"><span>거리순 노출</span></p>
      </div>

      <div className="list">
        <SearchSidebarList items={list} />
      </div>
    </div>
  )
}


function SearchSidebarList({ items = [] }) {
  const {
    state: { activeItem }
  } = React.useContext(SearchContext)

  return (
    <>
      <List direction="vertical">
        {items.map((item, key) => (
          <SearchSidebarListItem
            {...item}
            key={key}
            eachIndex={key}
            isActive={activeItem === item.id}
          />
        ))}

        <div className="space" />
      </List>
    </>
  )
}


function SearchSidebarListItem({ id, image, title, phone, address, website, info, eachIndex, isActive, category, link }) {
  const {
    changeActiveItem
  } = React.useContext(SearchContext)

  return (
    <HorizontalItem
      badge={eachIndex + 1}
      image={image}
      title={title}
      info={
        (
          <>
            <p className="line" data-id={id}>
              <span>{address}</span>
              <span className="highlight">{phone}</span>
            </p>
            
            <p>
              <a href={link} target="_blank">
                <em>상세보기</em>
              </a>
            </p>
          </>
        )
      }
      onClick={() => {
        changeActiveItem(id)
      }}
    />
  )
}
