import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core'
import Swiper from 'swiper'
import 'swiper/swiper-bundle.css'

import Title from '~ui/title/Title'
import './event.scss'

import event1 from '~/assets/images/event.png'
import event2 from '~/assets/images/dive_shot15.png'
import event3 from '~/assets/images/dive_shot9.png'

import arrow_left_white from "~assets/images/icon/arrow_left_white.png"
import arrow_right_white from "~assets/images/icon/arrow_right_white.png"
import diver from "~assets/images/diver.png"
import partner from "~assets/images/partner.png"


const events = [
  {
    id: 1,
    image: event1,
    title: 'EVENT\nBANNER',
    content: 'Everything We Know About Prescription Dive Masks and Corrective Lenses.'
  },
  {
    id: 2,
    image: event2,
    title: 'EVENT\nBANNER1',
    content: 'Everything We Know About Prescription Dive Masks and Corrective Lenses.'
  },
  {
    id: 3,
    image: event3,
    title: 'EVENT\nBANNER2',
    content: 'Everything We Know About Prescription Dive Masks and Corrective Lenses.'
  }
]


const Event = () => {
  const [target, setTarget] = useState()
  const [now, setNow] = useState(0)
  const [dialog, setDialog] = useState(false)
  const [dialogType, setDialogType] = useState('pre')

  const init = () => {
    setTarget(new Swiper(`.bgSlide`)
      .on('slideChange', ({ activeIndex }) => {
        setNow(activeIndex)
      })
    )
  }

  const moveSlide = (dir) => {
    let nowId = now
    let max = events.length
    
    nowId += dir
    nowId = nowId < 0 ? 0 : (nowId > max - 1 ? max - 1 : nowId)
    
    setNow(nowId)
    target.slideTo(nowId)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    alert('신청 처리 되었습니다')
    setDialog(false)
  }

  useEffect(() => {
    init()
  }, [])


  return (
    <div className="event">
      <Title label="이벤트" link="/" />

      <div className="eventSlide">
        <div className="bgSlide">
          <div className="swiper-wrapper">
            {events.map((v, i) => (
              <div className="swiper-slide" key={i}>
                <img src={v.image} alt="slider" />
              </div>
            ))}
          </div>
        </div>

        <div className="content">
          <h6>aquanode</h6>
          
          {events.map((v, i) => (
            <div className={`changeTextArea ${i === now ? 'active' : ''}`} key={i}>
              <h2>
                {v.title.split('\n').map((txt, k) => (
                  <div key={k}>
                    {txt}
                  </div>
                ))}
              </h2>

              <p>
                {v.content}
              </p>
            </div>
          ))}


          <button>자세히</button>

          <div className="controller">
            <button onClick={() => moveSlide(-1)}>
              <img src={arrow_left_white} alt="icon" />
            </button>

            <button onClick={() => moveSlide(1)}>
              <img src={arrow_right_white} alt="icon" />
            </button>
          </div>
        </div>
      </div>

      <div className="recruitment">
        <div className="item" onClick={() => { setDialog(true); setDialogType('pre') }}>
          <div>
            <h2>다이버 강사 모집</h2>

            <p>
              바다속 세로운 세계로 인도할
              <br />
              다이브 강사님을를 모십니다.
            </p>
          </div>

          <img src={diver} alt="icon" />
        </div>

        <div className="item" onClick={() => { setDialog(true); setDialogType('shop') }}>
          <div>
            <h2>제휴 업체 모집</h2>
            
            <p>
              아쿠아노드와 함께 바다로<br />
              나아가실 다이샵/리조트<br />
              를 모십니다.
            </p>
          </div>

          <img src={partner} alt="icon" />
        </div>
      </div>

      <Dialog open={dialog} onClose={() => setDialog(false)} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{dialogType === 'pre' ? '다이버 강사 모집' : '제휴 업체 모집'}</DialogTitle>

          <DialogContent>
            {dialogType === 'pre' && (
              <>
                <TextField
                  variant="outlined"
                  label="이름(실명)"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  type="email"
                  label="이메일"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  type="tel"
                  label="연락처"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="다이빙 로그 수"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="다이빙 강사 자격증 번호"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="소속 협회"
                  margin="normal"
                  required
                  fullWidth
                />
              </>
            )}

            {dialogType === 'shop' && (
              <>
                <TextField
                  variant="outlined"
                  label="업체명"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="사업자번호"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  type="tel"
                  label="연락처"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  label="주소"
                  margin="normal"
                  required
                  fullWidth
                />

                <TextField
                  variant="outlined"
                  type="email"
                  label="이메일"
                  margin="normal"
                  required
                  fullWidth
                />
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button type="button" onClick={() => setDialog(false)}>닫기</Button>
            <Button type="submit" color="secondary">신청</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}

export default Event