import React from 'react'
import { useRecoilState } from 'recoil'

import './inputSearch.scss'
import SearchIcon from '~assets/images/icon/search.png'
import { searchKeyword } from '~/stores/ui/searchInput'


export default function InputSearch({ ...props }) {
  const [keyword, setKeyword] = useRecoilState(searchKeyword)
  
  return (
    <div className="UI-inputSearch">
      <input {...props} value={keyword} onChange={(e) => {
        let val = e.target.value
        setKeyword(val)
      }} />

      <img src={SearchIcon} alt="icon" />
    </div>
  )
}
