import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

import Header from '~uCompon/header/Header'
import EduList from '~uCompon/edu/EduList'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'
import InputSearch from '~ui/inputSearch/InputSearch'
import Footer from '~uCompon/footer/Footer'
import ButtonList from '~ui/buttonList/ButtonList'
import Loading from '~ui/loading/Loading'

import '~uCompon/tour/tour.scss'


const OpenWater = () => {
  const { type } = useParams()
  const pointPlace = ['전체', '필리핀', '인도네시아', '팔라우', '제주도', '오키나와']
  const [place, setPlace] = useState(['전체'])
  const [loading, setLoading] = useState(true)

  const label = {
    "open-water": {
      en: 'LEVEL 01 | OPEN WATER를 찾아보세요!',
      kr: '오픈워터'
    },
    "advanced": {
      en: 'LEVEL 02 | ADVANSED OPEN WATER를 찾아보세요!',
      kr: '어드밴스드 오픈워터'
    },
    "rescu": {
      en: 'LEVEL 03 | RESCUE를 찾아보세요!',
      kr: '레스큐'
    },
    "dive-master": {
      en: 'LEVEL 04 | DIVE MASTER를 찾아보세요!',
      kr: '다이브마스터'
    },
    "special-t": {
      en: 'LEVEL 05 | SPECIAL-T를 찾아보세요!',
      kr: '스페셜티'
    },
    "instruct": {
      en: 'LEVEL 06 | ASSIST INSTRUCT를 찾아보세요!',
      kr: '강사'
    },
  }[type]


  return (
    <div className="TourPage">
      <Header />

      <Box className="rap" mt="5rem" mb="1rem">
        <InputSearch placeholder={label.en} />
        <div className="pointPlace">
          <ButtonList 
            list={pointPlace}
            multiple={true}
            changeActive={(val) => {
              setPlace(val)
            }}
            dir="left"
          />
        </div>
      </Box>

      <Box className="rap" pb="6rem">
        {loading && (
          <Loading timer={(v) => setLoading(v)} />
        )}

        <div className={clsx('fadeAnimation', loading && 'hide')}>
          <EduList 
            filterData={{ place }} 
            defList={type !== 'open-water' && []}
            label={label.kr}
          />
        </div>
      </Box>

      <Footer />

      <MobileMenu />
    </div>
  )
}

export default OpenWater