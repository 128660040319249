import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { instrListState } from '~uStores/list/instructor'
import { searchKeyword } from '~/stores/ui/searchInput'
import Title from '~ui/title/Title'
import CardList from '~ui/card/CardList'

import '~uCompon/tour/tourList.scss'
import './instrList.scss'
import { useHistory } from 'react-router-dom'


const InstrList = ({ filterData, search = true }) => {
  const history = useHistory()
  const [instrList, reloadTour] = useRecoilState(instrListState)
  const [keyword, setKeyword] = useRecoilState(searchKeyword)
  const [order, setOrder] = useState('추천순')

  const places = filterData?.place || ['전체']
  const list = instrList
    .filter(v => ((v.nameKr.includes(keyword) || v.nameEn.includes(keyword)) || v.licence.includes('전체')) && ((places.includes('전체') ? true : places.includes(v.licence))))

  list.sort((a, b) => {
    switch (order) {
      case "추천순":
        return a.id > b.id ? 1 : -1  
        break;
      case "최근 등록순":
        return a.id < b.id ? 1 : -1  
        break;
      case "리뷰순":
        return a.reviews.length > b.reviews.length ? 1 : -1  
        break;
    }
  })

  useEffect(() => {
    setKeyword('')
  }, [])


  return (
    <div className="instrListPage tourList">
      {search && (
        <Title 
          label={<div>강사 검색결과 - 총 <span>{list.length}</span>개</div>} 
          order={true} 
          orderData={['추천순', '인기순', '리뷰순', '최근 등록순']}
          changeOrder={(val) => setOrder(val)} 
        />
      )}

      <div className="instrList">
        {list.map((v, i) => (
          <div className="item" key={i} onClick={() => history.push(`/instr/detail/${v.id}`)}>
            <div className="profile">
              <div className="imgBox">
                <img src={v.profile} />
              </div>
              <div className="nameBox">
                <h3 className="nameKr">{v.nameKr}</h3>
                <h3 className="nameEn">{v.nameEn}</h3>
              </div>
            </div>
            <div className="content">
              <div className="info">
                <h4>소속(라이센스)</h4>
                <p>{v.licence}</p>
              </div>
              <div className="info">
                <h4>등급</h4>
                <p>{v.rating}</p>
              </div>
              <div className="info">
                <h4>경력</h4>
                <p>{v.career}</p>
              </div>
              <div className="info">
                <h4>등록된 리뷰 건수</h4>
                <p>{v.reviews.length}개</p>
              </div>
            </div>

            <div className="hash">
              <h4>주 활동 포인트</h4>
              <div className="hashList">
                {v.points.map((v, i) => (
                  <div className="item">{v}</div>
                ))}
              </div>
            </div>
          </div>
        ))}

        {!list.length ? (
          <div className="notListData">검색된 강사가 없습니다.</div>
        ) : ''}
      </div>

    </div>
  )
}

export default InstrList