import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { eduListState } from '~uStores/list/edu'
import { searchKeyword } from '~/stores/ui/searchInput'
import CardList from '~ui/card/CardList'
import Title from '~ui/title/Title'


const EduList = ({ filterData, defList, label }) => {
  const [eduList, reload] = useRecoilState(eduListState)
  const [keyword, setKeyword] = useRecoilState(searchKeyword)
  const [order, setOrder] = useState('추천순')
  const [list, setList] = useState([])

  useEffect(() => {
    const places = filterData?.place || ['전체']
    let data = (defList || eduList).filter(v => (v.place.includes(keyword) || v.title.includes(keyword)) && (places.includes('전체') ? true : places.includes(v.place)))

    data.sort((a, b) => {
      switch (order) {
        case "추천순":
          return a.id > b.id ? 1 : -1  
          break;
        case "신상품순":
          return a.id < b.id ? 1 : -1  
          break;
        case "낮은 가격순":
          return a.point > b.point ? 1 : -1  
          break;
        case "높은 가격순":
          return a.point < b.point ? 1 : -1  
          break;
        case "평점 높은순":
          return a.score < b.score ? 1 : -1  
          break;   
      }
    })

    setList(data)
  }, [eduList, order, filterData])

  useEffect(() => {
    setKeyword('')
  }, [])


  return (
    <div>
      <Title 
        label={<div>{label} 검색결과 - 총 <span>{list.length}</span>개</div>} 
        order={true} 
        changeOrder={(val) => setOrder(val)} 
      />
      <div className="tourList">
        <CardList 
          list={list}
          link="/dive-edu/detail"
          label="검색된 다이브 교육 결과가 없습니다."
        />
      </div>
    </div>
  )
}

export default EduList