import React from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { Icon, Box } from '@material-ui/core'
import clsx from 'clsx'

import '~uCompon/scuba/scubaDetail.scss'
import MarkerIcon from '~/assets/images/icon/marker.svg'
import { diveShopListState } from '~uStores/list/dive'
import { getScoreLabel } from '~/utils'

import Image from '~ui/image/Image'
import Map from '~ui/map/Map'
import Loading from '~ui/loading/Loading'
import Review from '~uCompon/review/Review'
import Header from '~uCompon/header/Header'
import Footer from '~uCompon/footer/Footer'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'


export default function ScubaDetail() {
  const { id } = useParams()
  const data = useRecoilValue(diveShopListState)[id]
  const [loading, setLoading] = React.useState(true)

  const reviewCount = data.reviews.length
  const reviewScoreAvg = Number((data.reviews.reduce((acc, next) => acc + next.score, 0) / reviewCount).toFixed(1).replace(/\.0$/, '')) || 0

  
  return (
    <div className="scubaDetailPage">
      <Header active="스쿠버샵" />

      {loading && (
        <Box width="100%" display="flex" justifyContent="center">
          <Loading timer={v => setLoading(v)} />
        </Box>
      )}

      <div className={clsx('fadeAnimation', loading && 'hide')}>
        <div className="rap container">
          <div className="leftContainer">
            <div className="head">
              <div className="left">
                <div className="starContainer">
                  {Array(data.score).fill().map((_, i) => (
                    <Icon key={i}>star</Icon>
                  ))}
                </div>

                <h2>{data.title}</h2>
                <h3>{data.place}</h3>

                <span>
                  <img alt="icon" src={MarkerIcon} />
                  {data.place}
                </span>
              </div>

              <div className="right">
                <div className="scoreContainer">
                  <div className="text">
                    <div className="scoreLabel">{getScoreLabel(reviewScoreAvg)}</div>
                    <div className="reviewCount">{reviewCount.toLocaleString()}개의 이용 후기</div>
                  </div>

                  <div className="scoreBox">{reviewScoreAvg}</div>
                </div>
              </div>
            </div>
            
            <Image width="100%" height="25rem" src={data.img} />

            <h2 className="sectionTitle">스쿠버샵 정보</h2>

            <div className="infoItem">
              <h6>전화번호</h6>

              <div className="content">
                {data.phone}
              </div>
            </div>

            <div className="infoItem">
              <h6>소개</h6>

              <div className="content">
                {data.desc}
              </div>
            </div>

            <div className="infoItem">
              <h6>지도</h6>

              <div className="content">
                <Map center={{ lat: data.lat, lng: data.lng }} />
              </div>
            </div>

            <h2 className="sectionTitle">후기 5</h2>

            <Review list={data.reviews} />
          </div>

          <div className="rightContainer">
            <div className="sideContent">
              <div className="costContainer">
                <div className="infoList">
                  <div className="info">{data.title} - {data.cost.toLocaleString()}원</div>
                </div>
                <div className="cost">
                  <p>총 금액</p>
                  <div>{data.cost.toLocaleString()}<span>원</span></div>
                </div>

                <button onClick={() => {
                  window.localStorage.getItem('user') ? alert('서비스준비중입니다.') : alert('로그인 후 사용 가능합니다.')
                }}>예약하기</button>

                <button onClick={() => {
                  window.localStorage.getItem('user') ? alert('위시리스트에 담겼습니다.') : alert('로그인 후 사용 가능합니다.')
                }} className="wishBtn">위시리스트에 담기</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobileMenu />

      <Footer />
    </div>
  )
}
