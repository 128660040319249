import React from 'react'

import './loading.scss'
import LoadingIcon from '~assets/images/icon/loading.gif'

export default function Loading({ timer }) {
  setTimeout(() => {
    timer && timer(false)
  }, 1000)

  return (
    <div className="UI-loading">
      <div className="container">
        <img src={LoadingIcon} alt="test" />
        <p>로딩중</p>
      </div>
    </div>
  )
}