import { atom, selector } from 'recoil'

import tour1 from '~/assets/images/tour1.png'
import tour2 from '~/assets/images/tour2.png'
import tour3 from '~/assets/images/tour3.png'
import tour4 from '~/assets/images/tour4.png'

import more1 from '~/assets/images/more1.jpg'
import more2 from '~/assets/images/more2.jpg'
import more3 from '~/assets/images/more3.jpeg'
import more4 from '~/assets/images/more4.jpeg'
import more5 from '~/assets/images/more5.jpeg'
import more6 from '~/assets/images/more6.jpg'
import more7 from '~/assets/images/more7.jpg'
import more8 from '~/assets/images/more8.jpg'
import more9 from '~/assets/images/more9.jpeg'
import more10 from '~/assets/images/more10.jpg'
import more11 from '~/assets/images/more11.jpeg'

import a1 from '~/assets/images/a1.jpg'
import a2 from '~/assets/images/a2.jpg'
import a3 from '~/assets/images/a3.jpg'
import a4 from '~/assets/images/a4.jpg'
import a5 from '~/assets/images/a5.jpg'
import a6 from '~/assets/images/a6.jpg'
import a7 from '~/assets/images/a7.jpg'
import a8 from '~/assets/images/a8.jpg'
import a9 from '~/assets/images/a9.jpg'
import a10 from '~/assets/images/a10.jpg'


export const tourListState = selector({
  key: 'tourListState',
  get: async ({ get }) => {
    return [
      {
        id: 1,
        img: tour1,
        images: [tour4, tour2, tour1, a1, a2, a3, more2, more5, more7],
        place: '제주도',
        title: '성산스쿠버와 함께하는 스킨스쿠버 체험',
        cnt: 5,
        score: 4,
        point: '100,000',
        lat: 33.4021604,
        lng: 126.8293241,
        phone: '010-8479-2781',
        reviews: [
          {
            score: 10,
            goodContent: '픽드랍 정시에 딱 해주시공 교육부터 체험까지 체계적으로 너무 잘 해주셨어용 저희 신랑도 대만족이랍니당',
            badContent: '없어용',
            date: '2019.08.29',
            nickname: '김**'
          },
          {
            score: 8,
            goodContent: `행복한 시간이엇어요!! 물고기들도 이쁘고 바다 속에서 움직이다니~ 처음이라 긴장햇는데 다들 도와주셔서 재밋엇어요^^`,
            date: '2019.08.29',
            nickname: '신**'
          },
          {
            score: 10,
            goodContent: `초보자 분들도 쉽게 다가갈수 있는 다이빙 경험 이었습니다.`,
            date: '2019.08.29',
            nickname: '이**'
          },
        ],
        desc: `
          포함 사항
          안전교육+풀세트 장비렌탈+샤워시설 이용
          
          불포함 사항
          구매 옵션별 포함사항 제외한 일체
          사진 및 동영상 촬영시 1인 10,000원의 추가비용이 발생
        `
      },
      {
        id: 2,
        img: tour2,
        images: [a10, a8, a7, a5, a3, more9],
        place: '제주도',
        title: '율랜드 스쿠버 다이빙 체험',
        cnt: 8,
        score: 3,
        point: '120,000',
        lat: 33.2501997,
        lng: 126.5587452,
        phone: '010-4930-1129',
        reviews: [
          {
            score: 9,
            goodContent: '여행 다녀온지 열흘이나 지나서야 후기 올리네요!! 체험 스쿠버다이빙 가성비 짱짱!!!!!',
            badContent: '나쁜점이 없는거 같네요',
            date: '2019.08.29',
            nickname: '이**'
          },
          {
            score: 8,
            goodContent: `비싸지 않은 가격에 스쿠버 다이빙 체험이 괜찮은 거 같음. 한국인 강사분이 잘 가르쳐주시고 현지인 가이드 또한 매우 열심히 가이드해줌.`,
            date: '2019.02.08',
            nickname: '송**'
          },
          {
            score: 10,
            goodContent: `역시 스노쿨링보다 보람차고 좋았습니다 손으로 직접만져 볼수도 있었고 수영할필요도 없어서 좋았네요 말이 통해서 편했건점도 한 몫햇습니다`,
            date: '2019.01.04',
            nickname: '조**'
          },
        ],
        desc: `
        [비치체험다이빙]
        ※ 체험 1~2일 전까지 사전예약 필수 (010-4255-4176)
        ※ 기상악화 또는 파도 높이에 따라 전날 또는 당일 취소될 수 있음
        - 운영시간 : 09:00 ~ 18:00
        - 연중무휴
        - 체험시간 : 2시간(교육 및 준비시간 포함)
        - 10세 부터 이용 가능 (10세 미만 이용 불가)
        - 준비물 : 수영복 또는 체험복, 수건
        (미 지참 시 체험복, 수건 판매 및 대여가능)
        ※ 체험다이빙 후 자격증 과정 등록 시 교육비 할인 등 자세한 내용은 문의바랍니다.)
        `
      },
      {
        id: 3,
        img: tour3,
        images: [a4, a3, a8, a9, a10, more10, tour1, tour2],
        place: '독도',
        title: '울릉도&독도 2박3일 패키지 여행 (대한민국/울릉도)',
        cnt: 2,
        score: 4,
        point: '390,000',
        lat: 37.7521080804166,
        lng: 128.875906144832,
        phone: '010-5903-5033',
        reviews: [
          {
            score: 9,
            goodContent: '만족스러워요! 시간이 후욱 가도라구요ㅋㅋ',
            date: '2020.06.26',
            nickname: '이**'
          },
          {
            score: 8,
            goodContent: `정말 친절하게 잘 가리켜줍니다. 숨쉬기 힘들어하면 침착하게 해서 도중에 포기하지 않게 도와줘요.`,
            date: '2020.04.01',
            nickname: '김**'
          },
          {
            score: 7,
            goodContent: `대체로 좋았습니다. 체험시간도 적당했습니다. 가이드뷴이 친절하게 설명해주셨습니다.`,
            date: '2020.03.25',
            nickname: '최**'
          },
        ],
        desc: `
        대한민국 국민이면 누구나 가보고 싶은 환상의 섬. 내나라 울릉도와 독도 2박3일 !
        봄, 여름, 가을, 겨울 언제 떠나도 좋은 안전한 청정지역!
        대한민국 정부 소유의 국유지로서 천연기념물 제336호로 지정되어 있는 섬! 독도!
        울릉도 일주도로 44.5Km 56년 만에 올해 전 구간 개통~!
        울릉도의 먹거리 대표는 오징어와 호박엿? 싱싱한 자연해산물과 산나물의 천국 -> 울릉도 특식 2식, 포함 호텔 조식 2식, 총 4식 제공 !
        후포항-울릉도는 최단거리로 빠른 뱃길! 2시간 20분대 운항!!
        셔틀이용시 서울출발(3곳) 또는 후포 현지 집결로 내 마음대로 출발지 선택!
        후포 현지 무료주차!! 전일정 강제쇼핑 NO!! 자유시간에 자유쇼핑!
        `
      },
      {
        id: 4,
        img: tour4,
        images: [more1, more3, more4, more6, more8, more11, a6],
        place: '제주도',
        title: '우도 다이브 스킨스쿠버 다이빙',
        cnt: 3,
        score: 5,
        point: '120,000',
        lat: 33.5042969,
        lng: 126.9452932,
        phone: '010-1474-1231',
        reviews: [
          {
            score: 10,
            goodContent: '친절하게 해주시고 다음에 꼭 다시 할 예정입니다.',
            date: '2020.08.23',
            nickname: '유**'
          },
          {
            score: 9,
            goodContent: `처음 와이프랑 하러갔는데 친절하고 안전하게 체험 잘했습니다~! 감사합니다`,
            date: '2020.07.02',
            nickname: '김**'
          },
          {
            score: 7,
            goodContent: `수영을 하지 못해도 스쿠버다이빙을 즐길수있었구요! 스쿠버다이빙 하기전에 스노클링 하는데 그건 어느정도 수영 하실수 있어야 즐길수있습니다!`,
            badContent: '조금 아쉬운점이 있다면 사진속엔 귀모양모자도 있고 사진 잘찍어주시는거같아서 선택했는데..귀모양모자가 아니였어요...ㅠㅠ',
            date: '2020.05.24',
            nickname: '이**'
          },
        ],
        desc: `
        [판매기간 및 유효기간]
        - 판매기간: 2020.09.28~2020.10.31
        - 이용기간: 2020.10.02~10.31
        ※ 9/30~10/1 대구아쿠아리움 휴무로 이용 불가
        [이용안내]
        - 입장연령: 전체연령가
        - 일부 체험 프로그램에 제한이 있을 수 있습니다.
        - 재판매 등 불법거래시 문제가 될 수 있는 점 참고 부탁드립니다.
        - 입장권과 유료 체험프로그램은 별도 운영됩니다.
        - 애완동물 반입이 금지되어 있습니다.
        - 모든 할인은 중복 적용되지 않습니다.
        - 안전상 시설점검으로 인한 일부 시설 이용 제한이 있을 수 있습니다.
        - 13세 이하 어린이는 보호자 동반 시 입장 가능합니다.
        
        `
      }
    ]
  }
})