import React, { useState } from 'react'
import { useRecoilState } from 'recoil'

import Visual from '~uCompon/home/Visual'
import Intr from '~uCompon/home/Intr'
import TourList from '~uCompon/tour/TourList'
import DiveShopList from '~uCompon/dive/DiveShopList'
import Title from '~ui/title/Title'
import DiveShot from '~uCompon/home/DiveShot'
import Contact from '~uCompon/home/Contact'
import DiveEdu from '~uCompon/home/DiveEdu'
import Footer from '~uCompon/footer/Footer'
import MobileMenu from '~uCompon/bottomMenu/MobileMenu'
import Event from '~uCompon/home/Event'
import Loading from '~ui/loading/Loading'


const Home = () => {
  const [loading, setLoading] = useState(true)
  

  return (
    <div className="homeWrap">
      {loading ? (
        <Loading timer={(v) => setLoading(v)} />
      ) : ''}
      <div className={`mainPage ${loading ? 'hide' : ''}`}>
        <div className="visual-rap rap">
          <Visual />
        </div>

        <div className="tourRecomd rap">
          <Title label="투어추천" link="/tours" />
          <TourList search={false} />
        </div>

        <Intr />

        <div className="tourRecomd rap">
          <Title label="추천 다이브샵" link="/scuba" />
          <DiveShopList search={false} />
        </div>

        <div className={`flexItem2 diveEduEventContainer rap`}>
          {localStorage['mobile'] ? (
            <div className="rap">
              <DiveEdu />
            </div>
          ) : <DiveEdu />}

          <Event />
        </div>

        <DiveShot />
        <Contact />
        <Footer />
        <MobileMenu />
      </div>
    </div>
  )
}

export default (Home)