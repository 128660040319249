import React from 'react'
import KakaoMaps from 'tenel-react-kakao-map'
import clsx from 'clsx'

import './map.scss'


export default function Map({ center, className, ...props }) {
  const [container, setContainer] = React.useState(null)
  const [mapCenter] = React.useState(center || { lat: 33.450701, lng: 126.570667 })

  return (
    <div className={clsx('UI-map', className)} {...props}>
      <div className="kakaoMapContainer" ref={(ref) => setContainer(ref)}>
        {container
          ? (
            <KakaoMaps.Map
              container={container}
              center={mapCenter}
            >
              <KakaoMaps.Marker position={mapCenter} />
            </KakaoMaps.Map>
          )
            : <span>로드중...</span>
        }
      </div>

      <a href={`https://map.kakao.com/link/map/${mapCenter.lat},${mapCenter.lng}`} className="moveKakaoMapLink">
        카카오맵
      </a>
    </div>
  );
}
